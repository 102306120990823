import Vue from 'vue'
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
//import validatorEs from 'vee-validate/dist/locale/es'

setInteractionMode('eager')
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

/* Validator.localize('es', validatorEs); */

extend('required', {
    ...required,
    message: 'El campo {_field_} es obligatorio',
});

extend('email', {
    ...email,
    message: 'El campo {_field_} no cumple con las caracteristicas.',
});

extend('rfc', value=> {
    const strongRegex = new RegExp("^([A-ZÑ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?(([A-Z]|[a-z]|[0-9]){3}))?$");
    if(strongRegex.test(value.toUpperCase())) {
        return true;
    }

    return 'El RFC no cumple con las caracteristicas.';
});

extend('verify_password', value => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if(strongRegex.test(value)) {
        return true;
    }

    return 'La contraseña debe ser mayor a 8 caracteres y contener al menos una mayúscula, una minúscula y un número.';
});

extend('numeros_positivos', value => {
    const strongRegex = new RegExp("^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$");
    if(strongRegex.test(value)) {
        return true;
    }

    return 'Solo se aceptan números positivos.';
});

extend('comision', {
    validate: value => {
        const strongRegex = new RegExp("^[1-9][0-9]?$|^100$");
        return strongRegex.test(value);
    },
    message: 'La comisión debe estar en el rango 1 a 100.',
});

extend('https', {
    validate: value => {
        const strongRegex = new RegExp('^https?:\/\/[A-Za-z0-9\.]*$');
        return strongRegex.test(value);
    },
    message: 'La direccion no cumple con las caracteristicas. Debe empezar con https://',
});
extend('https_puerto', {
    validate: value => {
        const strongRegex = new RegExp('(https?:\/\/[A-Za-z0-9\.]*$)|(https?:\/\/[A-Za-z0-9\.]*(([:]{1}[0-9]{1,5})))$');
        return strongRegex.test(value);
    },
    message: 'La direccion no cumple con las caracteristicas. Debe empezar con https://',
});
extend('curp', {
    validate: value => {
        const strongRegex = new RegExp('^([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([0-9A-Z]{1}[0-9]{1})$');
        return strongRegex.test(value);
    },
    message: 'La CURP no cumple con las caracteristicas',
});
extend('numero_millon', {
    validate: value => {
        //const strongRegex = new RegExp("^[1-9][0-9]?[0-9]?[0-9]?[0-9]?[0-9]?(\.[0-9]{1,2})?$|^1000000$");
        //const strongRegex = new RegExp("^[1-9]{1,6}(\.[0-9]{1,2})?$");
        const strongRegex = new RegExp("^[1-9]([0-9]{0,5})(\.[0-9]{1,2})?$");
        return strongRegex.test(value);
    },
    message: 'El valor ingresado debe ser menor a un millon y máximo dos decimales',
});
extend('numeros_positivos_sin_cero', {
    validate: value => {
        const strongRegex = new RegExp('^[1-9][0-9]*$');
        return strongRegex.test(value);
    },
    message: 'Solo se aceptan números positivos.',
});