<template>
    <div >
        <!-- <header class="ck-toolbar">
            <v-row class="pt-5 pl-3">
                <v-col cols="12" xs="5" sm="5" md="6" lg="8" xl="9"  class="py-0 pl-8">
                    <v-text-field
                        class="py-0 my-0"
                        v-model="search"
                        placeholder="Búsqueda general"
                        @keyup.enter="buscador()"
                        solo
                        flat
                        hide-details
                        clearable
                        prepend-inner-icon="mdi-magnify"
                    >
                    </v-text-field>
                </v-col>


                <v-col cols="12" xs="7" sm="7" md="6" lg="4" xl="3" class="pa-0 d-flex">
                    <v-col cols="12" xs="1" sm="2" md="2" lg="1" xl="1" class="pa-0 d-flex justify-center align-center" >
                        <v-menu buttom offset-y transition="scale-transition" class="elevation-0"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#828282"
                                    :ripple="false"
                                    v-bind="attrs"
                                    icon
                                    v-on="on"
                                    class="elevation-0 btnMenuText"
                                    id="no-background-hover"
                                    @click="showToolbarContent(0)"
                                    large
                                >
                                    <v-icon color='#96999A'>help_outline</v-icon>
                                </v-btn>
                            </template>


                            <div class="help-container">
                                <div class="help-menu-title">
                                    <span>Ayuda</span>
                                </div>
                                <div class="help-menu-item">
                                    <span>Preguntas frecuentes</span>
                                </div>
                                <div class="help-menu-item">
                                    <span>Contáctanos</span><br />
                                    <span class="extra-info">¿Preguntas? ¿Necesitas ayuda?</span>
                                </div>
                                <div class="help-menu-item">
                                    <span>Condiciones y privacidad</span>
                                </div>
                                <div class="help-menu-item">
                                    <span>Info. de la aplicación</span>
                                </div>
                            </div>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" xs="1" sm="2" md="2" lg="1" xl="1" class="pa-0 d-flex justify-center align-center">
                        <v-menu buttom offset-y transition="scale-transition" :close-on-content-click="false" class="elevation-0"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#828282"
                                    :ripple="false"
                                    v-bind="attrs"
                                    icon
                                    v-on="on"
                                    class="elevation-0 btnMenuText"
                                    id="no-background-hover"
                                    @click="showToolbarContent(1)"
                                    large
                                >
                                    <v-badge
                                        :value="notificationsNew.length"
                                        color="#5DD3E8"
                                        overlap
                                        :content="notRead > 9?'+9':notRead"
                                        offset-x="13"
                                        offset-y="15"
                                    >
                                        <v-icon color='#96999A' >mdi-bell-outline</v-icon>
                                    </v-badge>
                                    
                                </v-btn>
                            </template>

                            <div class="notification-container">
                                <div class="notification-header">
                                    <span>Notificaciones</span>
                                </div>
                                <div class="previous" v-if="notificaciones.length">
                                    <span>Tienes solicitudes nuevas</span>
                                    <notification-list
                                        :notifications="notificaciones"
                                        @updateNotifications="loadNotificaciones"
                                        @readNotification="notRead--"
                                    />
                                </div>
                                <div v-else class="empty">
                                    <img class="col-lg-12 text-center mb-10" src="/static/icon/notificaciones/sin_notificaciones.png">
                                    <span>Cuando el equipo empiece a tener alguna <br> actividad, te la mostraremos aquí.</span>
                                </div>
                                <div class="view-all" @click="showAllNotifications">
                                    <span>Ver todas las notificaciones</span>
                                </div>
                            </div>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" xs="10" sm="6" md="6" lg="8" xl="9" class="pa-0 d-flex justify-center align-center mr-3">
                        <v-menu buttom offset-y transition="scale-transition" class="elevation-0"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#828282"
                                    :ripple="false"
                                    v-bind="attrs"
                                    icon
                                    v-on="on"
                                    class="elevation-0 btnMenuText"
                                    id="no-background-hover"
                                    @click="showToolbarContent(2)"
                                >
                                    <img :src="usuario.foto_url" class="user-image" />
                                    <label class="user-name">{{ usuario_nombre }}</label>
                                    <v-icon class="">expand_more</v-icon>
                                </v-btn>
                            </template>

                            <div class="user-container">
                                <div
                                    class="user-menu-item"
                                    v-for="item in items"
                                    :key="item.title"
                                    @click="_logout(item.title)"
                                >
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                        </v-menu>
                    </v-col>
                </v-col>
            </v-row>
        </header> -->
        <v-app-bar
            color="transparent"
            class="elevation-0 ml-4 my-6"
        >
            <v-text-field
                class="py-0 my-0 mr-3"
                v-model="search"
                placeholder="Búsqueda general"
                @keyup.enter="buscador()"
                solo
                flat
                hide-details
                clearable
                prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
    
            <!-- <v-spacer></v-spacer> -->
    
            <v-menu buttom offset-y transition="scale-transition" class="elevation-0"> 
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="#828282"
                        :ripple="false"
                        v-bind="attrs"
                        icon
                        v-on="on"
                        class="elevation-0 btnMenuText"
                        id="no-background-hover"
                        @click="showToolbarContent(0)"
                        large
                    >
                        <v-icon color='#96999A'>help_outline</v-icon>
                    </v-btn>
                </template>


                <div class="help-container">
                    <div class="help-menu-title">
                        <span>Ayuda</span>
                    </div>
                    <div class="help-menu-item">
                        <span>Preguntas frecuentes</span>
                    </div>
                    <div class="help-menu-item">
                        <span>Contáctanos</span><br />
                        <span class="extra-info">¿Preguntas? ¿Necesitas ayuda?</span>
                    </div>
                    <div class="help-menu-item">
                        <span>Condiciones y privacidad</span>
                    </div>
                    <div class="help-menu-item">
                        <span>Info. de la aplicación</span>
                    </div>
                    <div class="help-menu-item" @click="reloadPage()">
                        <span>Recargar sistema</span>
                    </div>
                </div>
            </v-menu>
    
            <v-menu buttom offset-y transition="scale-transition" :close-on-content-click="false" v-model="menuNotificaciones" class="elevation-0"> 
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="#828282"
                        :ripple="false"
                        v-bind="attrs"
                        icon
                        v-on="on"
                        class="elevation-0 btnMenuText"
                        :class="notificationsNew.length>0?(notificationsNew.length>9?'mr-2':'mr-1'):('')"
                        id="no-background-hover"
                        @click="showToolbarContent(1)"
                        large
                    >
                        <v-badge
                            :value="notificationsNew.length"
                            color="#5DD3E8"
                            overlap
                            :content="notRead > 9?'+9':notRead"
                            offset-x="13"
                            offset-y="15"
                        >
                            <v-icon color='#96999A' >mdi-bell-outline</v-icon>
                        </v-badge>
                        
                    </v-btn>
                </template>

                <div class="notification-container">
                    <div class="notification-header">
                        <span>Notificaciones</span>
                    </div>
                    <div class="previous" v-if="notificaciones.length">
                        <span>Tienes solicitudes nuevas</span>
                        <notification-list
                            :notifications="notificaciones"
                            @updateNotifications="loadNotificaciones"
                            @readNotification="notRead--"
                        />
                    </div>
                    <div v-else class="empty">
                        <img class="col-lg-12 text-center mb-10" src="/static/icon/notificaciones/sin_notificaciones.png">
                        <span>Cuando el equipo empiece a tener alguna <br> actividad, te la mostraremos aquí.</span>
                    </div>
                    <div class="view-all cursor-pointer" @click="showAllNotifications">
                        <span>Ver todas las notificaciones</span>
                    </div>
                </div>
            </v-menu>

            

            <v-menu buttom offset-y transition="scale-transition" class="elevation-0"> 
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="#828282"
                        :ripple="false"
                        v-bind="attrs"
                        icon
                        v-on="on"
                        class="elevation-0 btnMenuTextNombre"
                        :class="usuario_nombre.length>15?'ml-2':''"
                        id="no-background-hover"
                        @click="showToolbarContent(2)"
                    >
                        <img :src="usuario.foto_url" class="user-image" />
                        <label class="user-name">{{ usuario_nombre }}</label>
                        <v-icon class="">expand_more</v-icon>
                    </v-btn>
                </template>

                <div class="user-container">
                    <div
                        class="user-menu-item"
                        v-for="item in items"
                        :key="item.title"
                        @click="_logout(item.title)"
                    >
                        <span>{{ item.title }}</span>
                    </div>
                </div>
            </v-menu>
        </v-app-bar>

        <template v-if="modal_perfil">
            <v-layout row justify-center>
                <v-dialog v-model="modal_perfil" persistent :max-width="max_width">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card>
                        <div>
                            <v-btn
                                v-if="accion == 'perfil'"
                                @click="cerrarModal()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar modal-pull-right mr-3 mt-3"
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        </div>
                        <v-card-title class="card_titulo card_texto pb-6">
                            <span class="modal-title" v-text="titulo_modal"></span>
                        </v-card-title>
                        <v-card-text :class="(accion == 'cambio_contraseña' || accion == 'cambio_password' || accion == 'cambio_logo' ) ? 'card_texto pb-0' : 'card_texto pt-8 pb-0'">
                            <v-container grid-list-md class="pa-0" key="unique">
                                <template v-if="accion == 'perfil'">
                                    <v-row class="pa-0">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="modal-user-image">
                                            <v-badge
                                                bottom
                                                color="transparent"
                                                offset-x="35"
                                                offset-y="40"

                                            >
                                                <v-btn slot="badge" fab x-small color="primary" @click="abrirModalFoto()">
                                                    <v-icon color="white" class="outlined_v_icon">
                                                        photo_camera
                                                    </v-icon>
                                                </v-btn>
                                                <v-avatar size="100" >
                                                    <v-img :src="usuario.foto_url"></v-img>
                                                </v-avatar>
                                            </v-badge>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="center">
                                            <span class=" modal-user-name">{{ usuario.nombre + ' ' + usuario.apellido_paterno }}</span>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                            <div class="modal-user-info">
                                                <v-icon class="" color="#828282">mdi-account-outline</v-icon>
                                                <span>{{ usuario.usuario }}</span>
                                            </div>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                            <div class="modal-user-info">
                                                <v-icon class="" color="#828282">mdi-briefcase-outline</v-icon>
                                                <span>{{ usuario.puesto }}</span>
                                            </div>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                            <div class="modal-user-info">
                                                <v-icon class="" color="#828282">mdi-phone-outline</v-icon>
                                                <span>{{ usuario.telefono }}</span>
                                            </div>
                                        </v-col>

                                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="pb-2">
                                            <div class="modal-user-info">
                                                <v-icon class="" color="#828282">mdi-email-outline</v-icon>
                                                <span>{{ usuario.email }}</span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-if="accion == 'cambio_contraseña'">
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                        <v-text-field
                                            outlined
                                            label="Contraseña actual"
                                            :type="show1 ? 'text' : 'password'"
                                            v-model="usuario.password"
                                            @keyup.enter="verificar('password_anterior')"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="show1 = !show1"
                                        />
                                    </v-col>
                                    <template v-if="aprovo_password">
                                        <v-icon class="icono-alert" style="padding-left: 15px; margin-top: -9%;"></v-icon>
                                        <!-- <span class="v-stepper__step__step" color="#FF5454" style="padding-left: 20px; margin-top: -6%;">
                                            <i class="fa fa-exclamation-triangle stroke-transparent" style="color: #FF5454; margin-top: -15px;"></i>
                                        </span> -->
                                        <div class="v-stepper__label" style="color: #FF5454; padding-left:5px; margin-top: -8%;">Contraseña incorrecta</div>
                                    </template>
                                </template>

                                <template v-if="accion == 'cambio_password'">
                                    <ValidationObserver ref="form" v-slot="{ invalid }">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nueva contraseña" rules="required|verify_password">
                                            <v-text-field
                                                id="nuevo_password"
                                                label="Nueva contraseña"
                                                outlined
                                                class="estiloLetras"
                                                v-model="nuevo_password"
                                                data-vv-name="contraseña"
                                                :error-messages="errors"
                                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="show2 = !show2"
                                                :type="show2 ? 'text' : 'password'"
                                            />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="px-0 py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Confirmar Contraseña" rules="required|verify_password">
                                            <v-text-field
                                                id="verificar_password"
                                                label="Confirmar contraseña"
                                                outlined
                                                class="estiloLetras"
                                                v-model="confirmacion"
                                                data-vv-name="confirmación"
                                                :error-messages="errors"
                                                @keyup.enter="verificar('confirmacion')"
                                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="show3 = !show3"
                                                :type="show3 ? 'text' : 'password'"
                                            />
                                            </ValidationProvider>
                                        </v-col>
                                     </ValidationObserver>
                                    <template v-if="password_igual">
                                        <v-icon class="icono-alert" style="padding-left: 15px; margin-top: -8%;"></v-icon>
                                        <div class="v-stepper__label" style="color:#FF5454; padding-left:20px; margin-top: -9%;">Las contraseñas no son iguales, favor de verificar.</div>
                                    </template>
                                </template>

                                <template v-if="accion == 'cambio_logo'">
                                    <div v-if="showDeleteLogo" class="d-flex">
                                        <v-col cols="12" xs="10" sm="10" md="10" lg="10" xl="10" class="pa-0">
                                            <v-text-field
                                                outlined
                                                readonly
                                                class="pt-0 mt-0"
                                                label="Selecciona una imagen"
                                                @click="almacenarImagen"
                                                v-model="nombreImagen"
                                            >
                                                <template slot="append">
                                                    <v-icon @click="almacenarImagen" class="tamIcono">attach_file</v-icon>
                                                </template>
                                            </v-text-field>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="imagen"
                                                accept="image/*"
                                                @change="onFilePickedImagen"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2" class="pt-2">
                                            <v-btn
                                                class="botonHover"
                                                icon
                                                fab
                                                dark
                                                small
                                                @click="eliminarLogo()"
                                            >
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </div>
                                    <template v-else>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="px-0">
                                            <v-text-field
                                                outlined
                                                readonly
                                                class="pt-0 mt-0"
                                                label="Selecciona una imagen"
                                                @click="almacenarImagen"
                                                v-model="nombreImagen"
                                            >
                                                <template slot="append">
                                                    <v-icon class="tamIcono">attach_file</v-icon>
                                                </template>
                                            </v-text-field>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="imagen"
                                                accept="image/*"
                                                @change="onFilePickedImagen"
                                            />
                                        </v-col>
                                    </template>
                                </template>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" v-if="accion != 'perfil'" @click="cerrarModal()">Cerrar</button>
                            <v-btn color="btnGuardar" v-if="accion == 'cambio_logo'" @click="guardarImagen()">Guardar</v-btn>
                            <v-btn color="btnGuardar" v-if="accion == 'cambio_contraseña'" @click="verificar('password_anterior')">Verificar</v-btn>
                            <v-btn color="btnGuardar" v-if="accion == 'cambio_password'" @click="verificar('confirmacion')">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>

        <v-dialog v-model="dialogFoto" persistent max-width="500px">
            <v-card>
                <ValidationObserver ref="formFoto" v-slot="{ invalid }">
                    <v-card-title class="justify-center card_titulo">
                        <div class="headerModal">
                            <h2 class="titleModal">{{ 'Sube tu foto' }}</h2>
                        </div>
                    </v-card-title>
                    <v-card-text class="card_texto">
                        <v-container grid-list-md class="pt-0">
                            <v-divider class="divider"></v-divider>
                            <v-row>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                    <Upload
                                        :tamanioArchivo="10485760" 
                                        :multiple="false"
                                        :extensionInput="'.jpg,.jpeg,.png'"
                                        :extensionDrop="'.jpg|.jpeg|.png'"
                                        :arrayFile="fotoUsuario"
                                        @vModelFile="(data)=> fotoUsuario = data"
                                    >
                                    </Upload>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end card_accion">
                        <button class="btnCerrar" @click="cerrarModalFoto()" :disabled="isSaving">Cerrar</button>
                        <v-btn class="btnGuardar" @click="guardarFoto()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import NotificationList from "@/components/widgets/list/NotificationList";
    import { mapActions, mapState } from "vuex";
    import VWidget from "@/components/VWidget";
    import Notify from "@/plugins/notify";
    import userApi from "../api/usuarios";
    import GetUrlImg from "@/plugins/Urlimagen";
    import notificacionesApi from "../api/notificaciones";
    import clienteApi from "../api/clientes";
    import moment from 'moment';
    import env from "@/plugins/enviroment";
    import Echo from "laravel-echo";
    window.Pusher = require('pusher-js');
    import Upload from "@/components/DragAndDrop.vue";
    moment.locale('es');

    export default {
        name: "ck-toolbar",
        components: {
            NotificationList,
            Upload,
        },
        data() {
            return {
                socket: [],
                notRead : null,
                items: [
                    {
                        icon: "account_circle",
                        href: "#",
                        title: "Perfil",
                    },
                    {
                        icon: "settings",
                        href: "#",
                        title: "Cambiar contraseña",
                        click: e => {
                            this.cambiar_contraseña();
                        }
                    },
                    {
                        icon: "power_settings_new",
                        href: "#",
                        title: "Salir",
                        click: e => {
                            window.getApp.this._logout();
                        }
                    }
                ],
                max_width: "",
                search: "",
                modal_perfil: false,
                titulo_modal: "",
                accion: "",
                usuario: {
                    id: 0,
                    nombre: "",
                    apellido_paterno: "",
                    apellido_materno: "",
                    puesto: "",
                    email: "",
                    usuario: "",
                    fecha_nacimiento: "",
                    telefono: "",
                    celular: "",
                    password: "",
                    primer_inicio_sesion: "",
                    rol_id: "",
                    foto_url: ""
                },
                rol_nombre: "",
                nuevo_password: "",
                confirmacion: "",
                aprovo_password: false,
                password_igual: false,
                user_vuex: {
                    email: "",
                    password: ""
                },
                usuario_nombre: "",
                id_usuario: null,
                notificaciones: [],
                ImgUrl: null,
                nombreImagen: "",
                archivoImagen: null,
                showUserContent: false,
                showNotificationContent: false,
                showHelpContent: false,
                show1: false,
                show2: false,
                show3: false,
                showDeleteLogo: false,
                menuNotificaciones: false,
                dialogFoto  : false,
                fotoUsuario : [],
                isSaving    : false
            }
        },
        watch: {
            notification: {
                handler: function() {
                    if(this.notification.length > 0) {
                        this.notificaciones = this.notification;
                        this.emitEvent();
                    } else {
                        this.notificaciones = this.notification;
                    }
                }
            }
        },
        computed: {
            ...mapState("auth", ["datos", "user", "foto_usr", "error", "menu", "logotipo"]),
            notificationsNew() {
                return this.notificaciones.filter(item => item.activo);
            },
            notificationsRecent() {
                return this.notificaciones.filter(item => moment(item.created_at).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'));
            },
            notificationsPrevious() {
                return this.notificaciones.filter(item => moment(item.created_at).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD'));
            }
        },
        methods: {
            ...mapActions("auth", ["logout", "update_password", "updateUserData", "updateUserFoto", "vuexUpdateLogo"]),
            showToolbarContent(index) {
                switch(index) {
                    case 0:
                        this.showHelpContent            = this.showHelpContent ? false : true;
                        this.showNotificationContent    = false;
                        this.showUserContent            = false;
                        break;

                    case 1:
                        this.showNotificationContent    = this.showNotificationContent ? false : true;
                        this.showUserContent            = false;
                        this.showHelpContent            = false;
                        this.loadNotificaciones();
                        break;

                    case 2:
                        this.showUserContent            = this.showUserContent ? false : true;
                        this.showNotificationContent    = false;
                        this.showHelpContent            = false;
                        break;
                }
            },
            async _logout(tipo) {
                switch (tipo) {
                    case "Perfil":
                        this.ver_perfil();
                    break;
                    case "Cambiar contraseña":
                        this.cambiar_contraseña();
                    break;
                    case "Cambiar logo":
                        this.cambiar_logo();
                    break;
                    case "Cambiar foto":
                        this.abrirModalFoto();
                    break;
                    case "Salir":
                        await this.logout();
                        this.$router.push("/");
                    break;
                }
            },
            buscador() {
                var datosLogin  = this.$session.get("usuario");
                let rol         = datosLogin.rol.name;
                if(this.search != "") {
                    if (rol != "checador") {
                        this.$router.push("/buscador/" + this.search);
                    }
                }
            },
            ver_perfil() {
                var datosLogin  = this.$session.get("usuario");
                let rol         = datosLogin.rol.name;
                if (datosLogin.rol.name == "empleado") {
                    this.$session.remove("detalleEmpleado");
                    this.$router.push("/kardex/detalleEmpleado");
                } else {
                    this.modal_perfil   = true;
                    this.max_width      = "356px";
                    this.titulo_modal   = "Mi perfil";
                    this.accion         = "perfil";
                    this.llena_datos();
                }
            },
            llena_datos() {
                this.usuario.id                 = this.datos.id;
                this.usuario.nombre             = this.datos.nombre;
                this.usuario.apellido_paterno   = this.datos.apellido_paterno;
                this.usuario.apellido_materno   = this.datos.apellido_materno;
                this.usuario.puesto             = this.datos.rol.display_name;
                this.usuario.email              = this.datos.email;
                this.usuario.usuario            = this.datos.usuario;
                this.usuario.telefono           = this.datos.telefono;
                this.usuario.celular            = this.datos.celular;
                this.rol_nombre                 = this.datos.rol.display_name;
                this.usuario.rol_id             = this.datos.rol.id;
                this.usuario.primer_inicio_sesion = false;
                this.usuario.password           = this.confirmacion;
                this.user_vuex.email            = this.usuario.email;
                this.user_vuex.password         = this.confirmacion;
                this.usuario.fecha_nacimiento   = "";

                if(this.datos.fecha_nacimiento != null) {
                    this.usuario.fecha_nacimiento = this.datos.fecha_nacimiento.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
                }
            },
            cerrarModal() {
                this.modal_perfil = false;
                this.resetValue();
            },
            async update() {
                this.llena_datos();
                this.usuario.fecha_nacimiento = this.datos.fecha_nacimiento;

                await this.update_password(this.user_vuex);
                await userApi.updatePassword(this.usuario)
                    .then(response => {
                        this.cerrarModal();
                        Notify.Success("Operación exitosa", "La contraseña ha sido actualizada correctamente.");
                    })
                    .catch(err => {
                        Notify.Alert("Error", error.response.data.message, null, false);
                    });
            },
            async verificar(tipo) {
                var self = this;
                if (tipo === "confirmacion") {
                    let isValid = await this.$refs.form.validate();
                        if (isValid) {
                        if (this.confirmacion == this.nuevo_password) {
                            this.password_igual = false;
                            this.update();
                        } else {
                            this.password_igual = true;
                        }
                    }
                } else if (tipo === "password_anterior") {
                    if (self.usuario.password === this.user.password) {
                        this.accion = "cambio_password";
                        this.aprovo_password = false;
                        setTimeout(function() {
                            document.getElementById("nuevo_password").focus();
                        }, 100);
                    } else {
                        this.aprovo_password = true;
                    }
                }
            },
            cambiar_contraseña() {
                this.modal_perfil   = true;
                this.max_width      = "420px";
                this.accion         = "cambio_contraseña";
                this.titulo_modal   = "¿Cambiar contraseña?";
            },
            cambiar_logo() {
                this.modal_perfil   = true;
                this.max_width      = "420px";
                this.accion         = "cambio_logo";
                this.titulo_modal   = "Cambiar logotipo";
                let datosLogin      = this.$session.get("usuario");
                if(this.logotipo != null){
                    this.showDeleteLogo = true;
                }
                else{
                    this.showDeleteLogo = false;
                }
            },
            guardarImagen() {
                var datosLogin  = this.$session.get("usuario");
                let formData    = new FormData();
                var self        = this;

                var update = false;
                formData.append("cliente_id", datosLogin.cliente_id);
                if(this.archivoImagen != null) {
                    formData.append("archivo", this.archivoImagen);
                    if(datosLogin.foto_empresa != null) {
                        formData.append("update_logo", datosLogin.foto_empresa);
                        update = true;
                    }
                }
                formData.append("update", update);

                clienteApi.updateLogo(formData)
                    .then(async response => {
                        this.cerrarModal();
                        await self.vuexUpdateLogo(response.url)
                        Notify.Success("Operación exitosa", "La imagen ha sido actualizada satisfactoriamente.");
                        //self.update_logo({usuario_id: datosLogin.id,sistema: self.user.sistema,update:true})
                        
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
            },
            async update_logo(data) {
                await this.updateUserData(data);
                if(!this.error) {
                    //this.$session.destroy();
                    this.$session.set("usuario", this.datos);
                    this.$session.set("menu", this.menu);
                    //this.$emit("refreshLogo",true);
                }
            },
            almacenarImagen() {
                this.$refs.imagen.click();
            },
            onFilePickedImagen(evento) {
                const img = evento.target.files;
                if (img[0] !== undefined) {
                    this.nombreImagen = img[0].name;
                    if (this.nombreImagen.lastIndexOf(".") <= 0) {
                        return;
                    }

                    const archivo = new FileReader();
                    archivo.readAsDataURL(img[0]);
                    archivo.addEventListener("load", () => {
                        this.archivoImagen = img[0];
                    });
                } else {
                    this.nombreImagen = "";
                    this.archivoImagen = null;
                }
            },
            resetValue() {
                this.usuario.id                 = 0;
                this.usuario.nombre             = "";
                this.usuario.apellido_paterno   = "";
                this.usuario.apellido_materno   = "";
                this.usuario.titulo             = "";
                this.usuario.puesto             = "";
                this.usuario.email              = "";
                this.usuario.usuario            = "";
                this.usuario.fecha_nacimiento   = "";
                this.usuario.telefono           = "";
                this.usuario.telefono_oficina   = "";
                this.usuario.extension          = "";
                this.usuario.celular            = "";
                this.rol_nombre                 = "";
                this.usuario.password           = "";
                this.usuario.primer_inicio_sesion = "";
                this.modal_perfil               = false;
                this.titulo_modal               = "";
                this.accion                     = "";
                this.nuevo_password             = "";
                this.confirmacion               = "";
                this.aprovo_password            = false;
                this.password_igual             = false;
                this.nombreImagen               = "";
                this.archivoImagen              = null;
            },
            emitEvent() {
                /* this.notification.forEach(item => {
                    this.socket.emit("new-notification", {
                        usuario_id: item.receptor_usuario_id,
                        modulo: item.modulo,
                        titulo: item.titulo,
                        descripcion: item.descripcion,
                        token_movil: item.token_movil
                    });
                }); */
            },
            loadNotificaciones() {
                notificacionesApi.getNotificaciones({usuario_id: this.id_usuario, paginate: true,estatus_id:2})
                    .then(response =>{
                         this.notificaciones = response.data;
                         this.notRead = response.no_leido;
                         })
                    .catch(error => Notify.ErrorToast(error.response.data.error));
            },
            showAllNotifications() {
                this.showNotificationContent = false;
                this.menuNotificaciones = false;
                this.$router.push('/notificaciones');
            },
            clickHandler(event) {
                const { target }    = event;
                const { $el }       = this;
                if(!$el.contains(target)) {
                    this.showNotificationContent = false;
                }
            },
            eliminarLogo(){
                var self = this;
                Notify.Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas eliminar el logo?",
                    "Eliminar",
                    () => {
                        let datosLogin = this.$session.get("usuario");
                        let param = {id:datosLogin.cliente_id, rol_name:"admin"};
                        clienteApi.deleteLogo(param).then(async response => {
                            this.cerrarModal();
                            await self.vuexUpdateLogo(null)
                            this.showDeleteLogo = false
                            Notify.Success("Operación exitosa", "El logo ha sido eliminado satisfactoriamente.");
                            //self.update_logo({usuario_id: datosLogin.id,sistema: self.user.sistema,update:true});
                        })
                        .catch(err => {
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        });
                    }
                )        
            },
            reloadPage(){
                window.location.reload(true);
            },
            abrirModalFoto(){
                this.dialogFoto = true;
            },
            cerrarModalFoto(){
                this.dialogFoto = false;
                this.fotoUsuario = [];
                this.isSaving = false;
            },
            guardarFoto() {
                if (this.fotoUsuario.length == 0) {
                    Notify.Warning("ADVERTENCIA", "No se ha seleccinado un archivo");
                    return;
                }

                this.isSaving = true;
                let formData = new FormData();
                formData.append("profile_picture", this.fotoUsuario[0]);
                formData.append("usuario_id", this.id_usuario)

                let datosUsuario = this.$session.get("usuario")
                datosUsuario.sistema="nominas"

                userApi.updateFoto(formData).then(async response => {
                    this.cerrarModalFoto();
                    Notify.Success("Operación exitosa", "La foto de usuario ha sido actualizada satisfactoriamente.");
                    await this.updateUserFoto(response.data.path) 
                    this.usuario.foto_url = this.ImgUrl + this.foto_usr;
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            }
        },
        mounted() {
            var datosLogin = this.$session.get("usuario");

            if(datosLogin != undefined) {
                this.usuario_nombre = datosLogin.nombre;
                this.id_usuario     = datosLogin.id;
                this.loadNotificaciones();
            }

            if (datosLogin.rol.name == "admin") {
                this.items.splice(2, 0, {
                    icon: "account_circle",
                    href: "#",
                    title: "Cambiar logo",
                });
            }
            else if(datosLogin.rol.name == "empleado"){
                this.items.splice(2, 0, {
                    icon: "account_circle",
                    href: "#",
                    title: "Cambiar foto",
                });
            }
            document.addEventListener('click', this.clickHandler);
            console.log(env.serverURL);

            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'ASDASD2121',
                wsHost: env.serverURL.url,
                wsPort: env.pusherPort,
                wssPort: env.pusherPort,
                forceTLS: env.serverURL.tls,
                disableStats: true,
                enabledTransports: ['ws', 'wss']
            });

            window.Echo.channel(`notificaciones-${this.id_usuario}`).listen('IncidenciaMessage', (e) => {
                console.log(e);
            });

            window.Echo.channel(`notificaciones-generales`).listen('GeneralMessage', (e) => {
                console.log(e);
            });
        },
        created() {
            this.ImgUrl             = GetUrlImg.getURL();
            this.usuario.foto_url   = "/static/avatar/usuario4.jpg";

            if (this.ImgUrl != null && this.ImgUrl != "" && this.ImgUrl != undefined &&
                this.foto_usr != "" &&this.foto_usr != null &&this.foto_usr != undefined) {
                this.usuario.foto_url = this.ImgUrl + this.foto_usr;
            }

            
        }
    }
</script>

<style scoped>
    .center {
        text-align: center !important;
    }
    .modal-button {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        text-decoration: none !important;

        color: #A0A4A8;
        padding-bottom: 24px;
        padding-right: 20px;
        outline: 0;
    }
    .modal-button.save {
        background: transparent !important;
        box-shadow: none;
        color: #1E2245;
        margin-bottom: 20px;
        margin-right: 20px;
        text-transform: none !important;
    }

    .ck-toolbar {
        contain: layout;
        padding-top: 20px;
        display: block;
        flex: 1 1 auto;
        max-width: 100%;
        height: 100px;
        box-shadow: none;
        
        background-color: #EFEFEF;
    }
    .ck-toolbar__content {
        align-items: center;
        display: flex;
        position: relative;
        z-index: 0;
    }

    .searchbox {
        padding-left: 34px;
        width: 70%;
    }

    .search__input {
        width: 100%;
        padding: 12px 40px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: rgba(150, 153, 154, 0.7);
 
        background-color: #FFF;
        border-radius: 20px;
        transition: all 250ms ease-in-out;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition: transform 250ms ease-in-out;
    }
    .search__input:focus {
        outline: 0;
    }
    .search__input::placeholder:focus {
        padding-left: 0px;
    }
    .search__input::placeholder {
        color: rgba(150, 153, 154, 0.7);
        /*padding-left: 20px;*/
    }
    .search__input.search__icon {
        background-image: url("/static/icon/searchbox.png");
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position: 2% center;
    }

    .help-notification {
        width: 10%;
        display: flex;
        vertical-align: middle;
        padding-top: 5px;
        margin-right: 10px;
    }
    .help-notification > * {
        padding-left: 15px;
        cursor: pointer;
    }
    .help-notification > * > i {
        font-size: 32px;
        padding-right: 15px;
        color: #BDBDBD;
    }
    .help-notification > *:last-child > i {
        border-right-width: 2px;
        border-right-color: #96999A;
        border-right-style: solid;
    }
    .badge {
        background-color: #1E2245;
        border: 2px solid #efefef;
        margin-left: -32px;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 3px;
    }

    .user {
        padding-left: 15px;
        padding-top: 5px;
        display: contents;
        width: 20%;
    }
    .user-name {
        padding-left: 10px;
        color: #96999A;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        cursor: pointer; 
        min-width: 100px;
        max-width: 150px;
        overflow: hidden;
        

        /* margin-top: 11px; */
    }
    .user-image {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
    .user-icon {
        padding-left: 21px;
        margin-top: 5px;
        color: #96999A;
    }

    .user-container {
        width: 250px;
        padding: 5px 0px;
        background-color: #FFF;
    }
    div.user-menu-item {
        width: 100%;
        cursor: pointer;
        outline: 0;

        padding: 5px 16px;
    }
    div.user-menu-item span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.1px;
        text-align: left;

        color: #96999A;
    }
    div.user-menu-item:hover {
        background-color: #f5faff;
    }
    div.user-menu-item:hover span {
        color: #1E2245;
    }
    div.user .dropdown-menu {
        transform: translate(-40%, 0%) !important;
    }

    .notification-container {
        width: 500px;
        padding: 24px 0px;
        background-color: #FFF;
    }
    .notification-container .notification-header,
    .notification-container .view-all {
        padding-left: 44px; 
        margin-top:26px;
    }
    .notification-header span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #1E2245;
    }
    .notification-header i {
        float: right;
    }
    .notification-container .new span,
    .notification-container .previous span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        padding-left: 44px;
    }

    .empty{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #636997;
        padding:91px;
    }
    .notification-container .new {
        padding-top: 10px;
    }
    .notification-container .view-all {
        text-align: center;
        padding-top: 20px;
        margin-left: -40px;
    }
    .notification-container .view-all span{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #444975;
    }

    .help-container {
        width: 320px;
        height: 400px;
        padding-top: 29px;
        background-color: #FFF;
    }
    div.help-menu-title {
        padding-left: 46px;
    }
    div.help-menu-title span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: left;

        color: #1E2245;
    }
    div.help-menu-item {
        width: 100%;
        cursor: pointer;
        outline: 0;

        padding: 20px 0px 20px 46px;
    }
    div.help-menu-item span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;

        color: #1E2245;
    }
    div.help-menu-item span.extra-info {
        font-family: 'Montserrat';
        font-weight: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;

        color: #828282;
    }
    div.help-menu-item:hover {
        background-color: #f5faff;
    }
    div.help-menu-item:hover span {
        color: #1E2245;
    }
    div.close-container {
        float: right;
        padding-bottom: 19px;
        padding-right: 33px;
    }
    div.close-container span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;

        color: #A0A4A8;
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }
    .dropdown-menu {
        display: none;
        position: absolute;
        margin-top: 5px;
        background-color: #FFF;
        min-width: 160px;
        box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
        border-radius: 8px;
        z-index: 999;

        transform: translate(-90%, 0%);
    }
    .dropdown-menu.dp-notification {
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08) !important;
        border-radius: 20px !important;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    .modal-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;

        color: #1E2245;
    }
    .modal-user-image {
        padding: 0px 82px;
    }
    .modal-user-image .image {
        width: 108px;
        height: 108px;
        border-radius: 50%;
    }
    .modal-user-name {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2245;
    }
    .modal-user-info {
        display: flex;
        align-items: center;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
    }
    .modal-user-info span {
        padding-left: 12px;
        word-break: break-all !important;
    }


    #no-background-hover::before {
        background-color: transparent !important;
    }
    .v-menu__content{
        border-radius: 15px;
        margin-top: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    .btnMenuText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        color: #1E2245;
        text-transform: none;
    }
    .btnMenuTextNombre {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        color: #1E2245;
        text-transform: none;
        min-width: 200px;
        max-width: 250px;
        margin-right: 50px!important;
    }
    .pipe_icon{

    }
    #background-hover-item::before{
        color: #1E2245;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        text-transform: none;
    }
    .text-hover-item:hover{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        color: #1E2245;
        text-transform: none;
    }
    .text-hover-item{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        color: #96999A;
        text-transform: none;
    }
    .cursor-pointer{
        cursor:pointer;
    }

    .padding_toolbar{
        padding-top: 50px;
    }

    @media only screen and (min-width: 600px) {
        .padding_toolbar{
            padding-top: 40px;
        }
    }
    @media only screen and (min-width: 601px) and (max-width: 960px) {
        .padding_toolbar{
            padding-top: 40px;
        }
    }
    @media only screen and (min-width: 961px) and (max-width: 1264px) {
        .padding_toolbar{
            padding-top: 40px;
        }
    }
    @media only screen and (min-width: 1265px) and (max-width: 1904px) {
        .padding_toolbar{
            padding-top: 40px;
        }
    }
    @media only screen and (min-width: 1905px) {
        .padding_toolbar{
            padding-top: 50px;
        }
    }
</style>