export default{
    user        : null,
    token       : null,
    datos       : null,
    menu        : null,
    menu_router : null,
    foto_usr    : null,
    expires_at  : null,
    isLogged    : false,
    error       : false,
    errorMessage: "",
    logotipo    : null,
}