export function setDashboard(state,data) {
    state.draggListFather = data.draggListFather;
    state.draggListChild = data.draggListChild;
    state.cardColaboradores = data.cardColaboradores;
    state.cardContratos = data.cardContratos;
    state.cardCostolaboral = data.cardCostolaboral;
    state.cardPercepciones = data.cardPercepciones;
    state.cardPagos = data.cardPagos;
    state.fecha_inicio = data.fecha_inicio;
    state.fecha_final = data.fecha_final;
}


export function setFatherList(state,data) {
   state.draggListFather = data;
}

export function setChildList(state,data) {
    state.draggListChild = data;
 }

export function setCardColaboradores(state, data) {
    state.cardColaboradores = data;
}

export function setCardContratos(state, data) {
    state.cardContratos = data;
}

export function setCardCostoLaboral(state, data) {
    state.cardCostoLaboral = data;
}

export function setCardPercepciones(state, data) {
    state.cardPercepciones = data;
}

export function setCardPagos(state, data) {
    state.cardPagos = data;
}

export function setEnviar(state, data){
    state.enviar = data
}

export function setDashboardContratos(state, data){
    state.dashboardContratos = data
}

export function setDashboardCostoSocial(state, data){
    state.dashboardCostoSocial = data
}

export function setDashboardTotalCostoSocial(state, data){
    state.dashboardTotalCostoSocial = data
}


//Componente colaboradores
export function setVuexColaboradoresIngresos(state, data){
    state.vuexColaboradoresIngresos = data
}

export function setVuexColaboradoresRotacion(state,data){
    state.vuexColaboradoresRotacion = data
}

export function setVuexColaboradoresTotal(state, data){
    state.vuexColaboradoresTotal = data
}

export function setVuexColaboradoresTotalIngresos(state,data){
    state.vuexColaboradoresTotalIngresos = data
}

export function setVuexColaboradoresTotalRotacion(state, data){
    state.vuexColaboradoresTotalRotacion = data
}

export function setVuexColaboradoresShowIngresos(state, data){
    state.vuexColaboradoresShowIngresos = data
}

export function setVuexColaboradoresShowRotacion(state,data){
    state.vuexColaboradoresShowRotacion = data
}
//Fin componente colaboradores



//Componente pagos
export function setVuexPagosAxisX(state, data){
    state.vuexPagosAxisX = data
}

export function setVuexPagosSeries(state, data){
    state.vuexPagosSeries = data
}

export function setVuexPagoList(state, data){
    state.vuexPagoList = data
}

export function setVuexPagosTotal(state, data){
    state.vuexPagosTotal = data
}

export function setVuexPagosShow (state, data){
    state.vuexPagosShow = data
}
//Fin componente pagos

export function setVuexShowCardColaboradores(state, data){
    state.vuexShowCardColaboradores = data
}

export function setVuexShowCardContratos(state, data){
    state.vuexShowCardContratos = data
}

export function setVuexShowCardCostoSocial(state, data){
    state.vuexShowCardCostoSocial = data
}

export function setVuexShowCardPagos(state, data){
    state.vuexShowCardPagos = data
}