export default{
    draggListFather     :   [
                                {
                                    "name": "Colaboradores",
                                    "id": 1,
                                    "fixed": false
                                },
                                {
                                    "name": "Graficas",
                                    "id": 2,
                                    "fixed": false
                                },
                            ],
    draggListChild      :   [
                                {
                                    "name": "Contratos",
                                    "id": 1,
                                    "fixed": false
                                },
                                {
                                    "name": "Costo laboral",
                                    "id": 2,
                                    "fixed": false
                                },
                                {
                                    "name": "Percepciones",
                                    "id": 3,
                                    "fixed": false
                                },
                                {
                                    "name": "Pagos",
                                    "id": 4,
                                    "fixed": false
                                },
                            ],
    cardColaboradores   :   true,
    cardContratos       :   true,
    cardCostoLaboral    :   true,
    cardPercepciones    :   true,
    cardPagos           :   true,
    fecha_inicio        :   null,
    fecha_final         :   null,
    enviar              : false,
    dashboardContratos  : {},
    dashboardCostoSocial: {
        costoSocial:"0.00",
        isn: "0.00",
        imssPatron: "0.00",
        imssColaborador: "0.00",
        rvcPatron: "0.00",
        rcvColaborador: "0.00",
        infonavit: "0.00",
        impuestoFederal: "0.00"
    },
    dashboardTotalCostoSocial: "110.00",


    vuexColaboradoresIngresos: [],
    vuexColaboradoresRotacion: [],
    vuexColaboradoresTotal:0,
    vuexColaboradoresTotalIngresos:0,
    vuexColaboradoresTotalRotacion:0,
    vuexColaboradoresShowIngresos:false,
    vuexColaboradoresShowRotacion:false,


    vuexPagosAxisX:[],
    vuexPagosSeries:[],
    vuexPagoList:[],
    vuexPagosTotal:"0.00",
    vuexPagosShow: false,

    vuexShowCardColaboradores:true,
    vuexShowCardContratos:true,
    vuexShowCardCostoSocial:true,
    vuexShowCardPagos:true,
}