/* export const myGetterEnviar = (state) => {
    return state.enviar
} */

export function enviar(state) {
    return state.enviar
}

export function dashboardContratos(state){
    return state.dashboardContratos
}

export function dashboardCostoSocial(state){
    return state.dashboardCostoSocial
}

export function dashboardTotalCostoSocial(state){
    return state.dashboardTotalCostoSocial
}



//Componente colaboradores
export function vuexColaboradoresIngresos(state){
    return state.vuexColaboradoresIngresos
}

export function vuexColaboradoresRotacion(state){
    return state.vuexColaboradoresRotacion
}

export function vuexColaboradoresTotal(state){
    return state.vuexColaboradoresTotal
}

export function vuexColaboradoresTotalIngresos(state){
    return state.vuexColaboradoresTotalIngresos
}

export function vuexColaboradoresTotalRotacion(state){
    return state.vuexColaboradoresTotalRotacion
}

export function vuexColaboradoresShowIngresos(state){
    return state.vuexColaboradoresShowIngresos
}
export function vuesColaboradoresShowRotacion(state){
    return state.vuesColaboradoresShowRotacion
}
//Fin componente colaboradores



//Componente pagos
export function vuexPagosAxisX(state){
    return state.vuexPagosAxisX
}

export function vuexPagosSeries(state){
    return state.vuexPagosSeries
}

export function vuexPagoList(state){
    return state.vuexPagoList
}

export function vuexPagosTotal(state){
    return state.vuexPagosTotal
}

export function vuexPagosShow(state){
    return state.vuexPagosShow
}

//Fin componente pagos

export function vuexShowCardColaboradores(state){
    return state.vuexShowCardColaboradores
}

export function vuexShowCardContratos(state){
    return state.vuexShowCardContratos
}

export function vuexShowCardCostoSocial(state){
    return state.vuexShowCardCostoSocial
}

export function vuexShowCardPagos(state){
    return state.vuexShowCardPagos
}