import { ENVIROMENT_PARAMETROS } from "./variables"

const env = {
    //azureAPI: 'https://cliker-app.cognitiveservices.azure.com/',
    //azureKey: '566ea719505f45f1a699f529a553f711',
    azureAPI: getAzureApi(),
    azureKey: getAzureKey(),
    IMGUR_ENDPOINT: 'https://api.imgur.com/3/image',
    //IMGUR_CLIENT_ID: 'd8c053dd3c0981d',
    IMGUR_CLIENT_ID: '74a5581804c4cc3',
    GMAPS_API_KEY: 'AIzaSyCoQUJfaNmU6eEH1a8REP1oMLbCNhwzDMw',

    serverURL: getURL(),
    pusherPort: 6001,
}

function getURL() {
    var host = window.location.hostname;
    let params=ENVIROMENT_PARAMETROS
    
    if (host === "payroll.cliker.com.mx" || host === "www.payroll.cliker.com.mx") {
        params.url = "apigdth.cliker.com.mx";
        params.tls      = true;

    } else if (
        host === "devmid19.pbmg.com.mx" ||
        host === "www.devmid19.pbmg.com.mx" ||
        host === "payrolldev.pbmg.com.mx" ||
        host === "www.payrolldev.pbmg.com.mx"
    ) {
        params.url = "apidevmid19.pbmg.com.mx";
        params.tls      = true;
    }

    return params;
}

function getAzureApi(){
    var host = window.location.hostname;
    //let azureApi = 'https://cliker-dev.cognitiveservices.azure.com/'; //antiguo
    let azureApi = 'https://cliker-devface.cognitiveservices.azure.com/'; //nuevo 20/07/2023
    if (host === "payroll.cliker.com.mx" || host === "www.payroll.cliker.com.mx") {
        azureApi = 'https://cliker-app.cognitiveservices.azure.com/';
    } 
    else {
        //azureApi = 'https://cliker-dev.cognitiveservices.azure.com/'; //antiguo
        azureApi = 'https://cliker-devface.cognitiveservices.azure.com/'; //nuevo 20/07/2023
    }
    return azureApi;
}

function getAzureKey(){
    var host = window.location.hostname;
    //let azure_key = '894b819c435e4b3099f4bccbd6dd6346'; //antiguo
    let azure_key = 'ce7bb19f133142099d8833f5438e1057';//nuevo 20/07/2023
    if (host === "payroll.cliker.com.mx" || host === "www.payroll.cliker.com.mx") {
        azure_key = '566ea719505f45f1a699f529a553f711';
    } 
    else {
        //azure_key = '894b819c435e4b3099f4bccbd6dd6346';//antiguo
        azure_key = 'ce7bb19f133142099d8833f5438e1057';//nuevo 20/07/2023
        
    }
    return azure_key;
}

export default env;
