import Vue from "vue";
import "../src/plugins";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSession from "vue-session";
import vuetify from "./plugins/vuetify";
import Datetime from "vue-datetime";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueApollo from "vue-apollo";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from "apollo-boost";
import VueMask from "v-mask";
import VModal from "vue-js-modal";
import * as VueGoogleMaps from "vue2-google-maps";
import url from "../src/plugins/Urlimagen";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ECharts from 'vue-echarts';
import "echarts";

Vue.component('v-chart', ECharts)
Vue.use(CKEditor);
Vue.use(PerfectScrollbar)

const urlGraphQL = url.getURL() + "graphql";

const httpLink = new HttpLink({
  uri: urlGraphQL,
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("tokenPB");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache(),
 
});

const apolloProvider = new VueApollo({
  defaultClient: client,
});

Vue.use(VueMask);
Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCoQUJfaNmU6eEH1a8REP1oMLbCNhwzDMw",
    libraries: "places",
    language: "es",
    region: "MX",
  },
});

Vue.use(VueApollo);
Vue.use(VueSession);
Vue.use(VueAxios, axios);
Vue.use(Datetime);
Vue.use(VModal, {
  dynamic: true,
  dynamicDefaults: { clickToClose: false },
  injectModalsContainer: true,
});
window.$ = window.jQuery = require("jquery");

Vue.directive("draggable", {
  bind: function(el) {
    el.style.position = "absolute";
    var startX, startY, initialMouseX, initialMouseY;

    function mousemove(e) {
      var dx = e.clientX - initialMouseX;
      var dy = e.clientY - initialMouseY;
      el.style.top = startY + dy + "px";
      el.style.left = startX + dx + "px";
      return false;
    }

    function mouseup() {
      document.removeEventListener("mousemove", mousemove);
      document.removeEventListener("mouseup", mouseup);
    }

    el.addEventListener("mousedown", function(e) {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;

      startX = el.offsetLeft;
      startY = el.offsetTop;
      initialMouseX = e.clientX;
      initialMouseY = e.clientY;
      document.addEventListener("mousemove", mousemove);
      document.addEventListener("mouseup", mouseup);
      return false;
    });
  },
});

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
  data: {
    configuracionesIniciales : true,
  },
}).$mount("#app");
