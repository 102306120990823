export function setUser(state,data) {
    let menu = [];
    
    data.menu.forEach(item => {
        if(item.activo == true && (item.sistema == 'todos' || item.sistema == 'nominas')){
            if(item.hijos !=null && item.hijos.length > 0){
                item.hijos.forEach(child=>{
                    if(child.activo == true){
                        menu.push(child.url);
                    }
                });
            }else{
               menu.push(item.url); 
            }
        }
    });

    state.user          = data.user;
    state.isLogged      = true
    state.error         = false
    state.errorMessage  = ''
    state.token         = data.token;
    state.expires_at    = data.expires_at;
    state.datos         = data.datos;
    state.menu          = data.menu;
    state.menu_router   = menu;
    state.foto_usr      = data.foto_usr;
    state.logotipo      = data.logotipo;
}

export function logout(state) {
    state.user        = null;
    state.token       = null;
    state.datos       = null;
    state.menu        = null;
    state.menu_router = null;
    state.foto_usr    = null;
    state.expires_at  = null;
    state.isLogged    = false;
    state.error       = false;
    state.errorMessage= "";
    localStorage.removeItem("tokenPB");
}

export function authError(state, errorMessage) {
    state.error = true,
    state.errorMessage = errorMessage;
    state.user = null;
    state.isLogged = false;
    state.token = null;
}

export function update_password(state, user) {
    state.user = user;
}

export function update_cliente(state, data) {
    state.datos = data;
}

export function updateUserData(state, data) {
    let menu = [];
    
    data.menu.forEach(item => {
        if(item.activo == true && (item.sistema == 'todos' || item.sistema == 'nominas')){
            if(item.hijos !=null && item.hijos.length > 0){
                item.hijos.forEach(child=>{
                    if(child.activo == true){
                        menu.push(child.url);
                    }
                });
            }else{
               menu.push(item.url); 
            }
        }
    });

    state.datos         = data.datos;
    state.menu          = data.menu;
    state.menu_router   = menu;
    state.foto_usr      = data.foto_usr;
}

export function updateUserFoto(state, foto){
    state.foto_usr = foto;
}
export function vuexUpdateLogo(state, logo){
    state.logotipo = logo;
}
