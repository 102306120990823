import Vue from 'vue';

export default{
    getNotificaciones(parametros) {
        return Vue.axios.get("notificaciones/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getIncidenciasNotificaciones(parametros) {
        return Vue.axios.get("notificaciones/incidencias", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getNotificacion(id) {
        return Vue.axios.get("notificaciones/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    readNotificacion(parametros) {
        return Vue.axios.post("notificaciones/read", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}