import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from "./paths";
import store from "../store";
import Notify from '@/plugins/notify';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes: paths,
});

// router gards
router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let isLogged = store.state.auth.isLogged;
  let user = store.state.auth.datos;

  let rol = null;
  let menu_router = null;

  if (store.state.auth.datos != null) {
    rol = store.state.auth.datos.rol.name;
  }
  if (store.state.auth.menu != null) {
    menu_router = store.state.auth.menu_router;
    //console.log("menu_router",menu_router);
    
  }

  //next();
  /*console.log("to",to);
  console.log("auth",requiresAuth);
  console.log("rol",rol);
  
  console.log("logged",isLogged);*/

  /*if (requiresAuth && isLogged && rol == "checador") {
    next("/login");
  }*/
  if(to.path == "/404"){
      next();
  }else{
    if(requiresAuth && isLogged){
      if (rol == "checador") {
        if(to.path != "/asistencia/checador-webcam"){
          Notify.Warning("¡Denegado!","Tu Rol no permite el acceso a esta vista.");
          next("/asistencia/checador-webcam")
        }else{
          next();
        }
      }else{

        if(sessionStorage['vue-session-key'] === undefined){
          next("/");
        }
      
        if(menu_router.includes(to.path) || 
          to.path == "/kardex/detalleEmpleado" || 
          to.path == "/abonos" || 
          to.path == "/periodos" || 
          to.path == "/incidenciasNominas" || 
          to.path == "/detalleTimbre" || 
          to.name == "buscador" || 
          to.path == "/notificaciones" ||  
          to.path == "/checadorRelacion" || 
          to.path == "/detalleCapacitacion" ||
          to.path == "/detalleAcumulado"||
          to.path == "/detallePrecalculo"||
          to.path == "/detallePTU"||
          to.path == "/detalle" ||
          to.path == "/detalleCalculoAnual"||
          to.path == "/detalleTimbres"||
          to.path == "/detalleTimbreIlimitados"||
          to.path == "/documentos_activos" ||
          to.path == "/documentos"
          )
        {
          next();   
        }else{
          Notify.Warning("¡Denegado!","Tu Rol no permite el acceso a esta vista.");
        }
      }  //console.log("1,1");
    }else if(!requiresAuth && isLogged){
        //console.log("0,1");
        if(to.path == "/"){
          next();
        }else if(user.configuracion){
          //console.log("entro",user.configuracion);
          if(rol == 'admin'){
            //console.log("tipoadmin");
            if(to.path == "/bienvenido" || to.path == "/paso_uno" || to.path == "/paso_dos" || to.path == "/paso_tres"){
              next();
            }else{
              next("/dashboard");
            }
          }else if(rol == 'admin-empresa'){
            //console.log("tipoadminempresa");
            if(to.path == "/bienvenido" || to.path == "/paso_uno" || to.path == "/paso_tres"){
              next();
            }else{
              next("/dashboard");
            }
          }else{
            next("/dashboard");
          }
        }else{
          if(rol == "root"){
            next();
          }else{
            next("/dashboard");
          }
        }
        next();
    }else if(requiresAuth && !isLogged){
        //console.log("1,0");
        next("/");
    }else{
        console.log("0,0");
        if(to.path == "/bienvenido" || to.path == "/paso_uno" || to.path == "/paso_dos" || to.path == "/paso_tres"){
          next("/");
        }
        next();
    }
  }
  
  

  /*if (requiresAuth && !isLogged) {
    //apartir de aqui comence
    next("/login");
  } 
    /*else if(from.path != '/login' && from.path != '/paso_uno' && from.path != 'paso_dos' && to.path == '/paso_tres' && isLogged){
    next(from.path);
  }*/
    /* else if(from.path == "/paso_dos" && to.path == "/paso_uno"){
    next(from.path);
  }
  else if(from.path == "/paso_tres" && to.path == "/paso_dos"){
    next(from.path);
  } */
  /*else if ((to.path == "/bienvenido" || to.path == "/paso_uno" || to.path == "/paso_dos" || to.path == "/paso_tres") && rol == "root" && isLogged) {
    next("/Dashboard");
  } else if ((to.path == "/paso_uno" || to.path == "/paso_dos") && rol == "admin-empresa" && isLogged) {
    next("/paso_tres");
  } else {
    next();
  }*/

});

router.afterEach((to, from) => {
  sessionStorage.setItem("rutaFrom", from.name);
});

export default router;
