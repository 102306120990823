import Vue from 'vue';

export default {

    /*
    Entrada: parametros Objeto user{}
    Salida: Objeto user{} con token
    Descripción: Se envia el email, contraseña y sistema y nos regresa un objeto 
                 usuario con foto, token y varios parametros mas para llenar el 
                 perfil en el sistema y validaciones por rol,
    */
    login(parametros) {
        return Vue.axios.post("auth/login", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    /*
    Entrada: parametros Objeto user{} de vuex
    Salida: 
    Descripción: Se envia el usuario logueado para invalidar el token.
    */
    logout(parametros) {
        return Vue.axios.get("logout", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

    /*
    Entrada: parametros Objeto user{} de vuex
    Salida: Objeto user{}
    Descripción: Se envia el el ubjeto user para volver a cargar sus 
                 datos de sesion depues de alguna actualizacion en su 
                 perfil como el cambio de contraseña o el logotipo 
                 de su empresa,
    */
    updateUserData(parametros) {
        return Vue.axios.post("refresh", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}