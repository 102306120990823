<template>
  <div class="notification-panel">
    <template v-for="(item, index) in notifications.slice(0, 10)" >
      <div :key="index + '-list'" @click="updateNotification(item)">
        <div class="notification-item py-3">
          <div class="circle mr-3" :style="'background:'+getColorNotification()">
            <p >{{item.titulo.charAt(0)}}</p>
          </div>
          <v-row>
            <v-col cols="12" class="py-0">
              <p class="title mb-0">{{item.titulo}}</p>
            </v-col>
            <v-col cols="12" class="py-0">
              <p class="subtitle my-0" > {{item.descripcion }}</p>
            </v-col>
            <v-col cols="12" class="py-0">
              <p class="time my-0 ">{{ timeAgo(item.created_at) }}</p>
            </v-col>
          </v-row>
          <span :class="['badge', !item.activo?'grey':'']"></span>
        </div>
        
      </div>
      
    </template>
  </div>
</template>

<script>

//IMPORTS

//APIS
import notificacionesApi from "@/api/notificaciones";

//PLUGINS
import Notify from '@/plugins/notify';
import moment from 'moment';

//CONFIGURATION
moment.locale('es');

export default {

  /*PORPS: variables recibidad del componente padre
           por medio  de una variable tipo prop.*/
  props: {
    //notifications: array[] de objetos notificaciones
    notifications: {
      type: Array,
      default: () => ([]),
    }
  },

  //DATA
  data: () => ({
  }),

  //METHODS
  methods: {

    //FUNCIONES GENERALES

    /*
    Entrada:
    Salida: color cadena con el color en exadecimal
    Descripcion: Retorna un color al azar para el icono de 
                 la notificacion entre las 13 opciones existentes.
    */
    getColorNotification(){
      var color = "#2DB9D1";
      var num = Math.floor(Math.random() * (13 - 1));
      
      switch(num){
        case 1:
          color = "#2DB9D1";
          break;
        case 2:
          color = "#2D9CDB";
          break;
        case 3:
          color = "#636997";
          break;
        case 4:
          color = "#9B51E0";
          break;
        case 5:
          color = "#BB6BD9";
          break;
        case 6:
          color = "#3E4BBA";
          break;
        case 7:
          color = "#1E2245";
          break;
        case 8:
          color = "#E8404E";
          break;
        case 9:
          color = "#F2C94C";
          break;
        case 10:
          color = "#F2994A";
          break;
        case 11:
          color = "#219653";
          break;
        case 12:
          color = "#6FCF97";
          break;
        case 13:
          color = "#04C456";
          break;
      }

      return color;
    },

    /*
    Entrada: value string de fecha
    Salida: string del tiempo transcurrido
    Descripcion: Se utiliza la libreria moment para sacar 
                 la diferencia de tiempo entre la fecha 
                 recibida y hoy.
    */
    timeAgo(value) {
      return moment(value).fromNow();
    },

    /*
    Entrada: data Objeto notificacion{} 
    Salida:
    Descripción: Ejectuta el API readNotificacion para 
                 actualizar la misma y ponerla como leida
                 y actualizando la cantidad de notificaciones
                 por leer con $emit("updateNotificacion").
    */
    updateNotification(data) {
      if(data.activo)
      notificacionesApi.readNotificacion({notificacion_id : data.id})
        .then((response) => {
          data.activo = false;
          this.$emit("readNotification");
        }).catch((error) => {
          Notify.ErrorToast(error.response.data.message);
        });
    }
  },

};
</script>

<style scoped>
  .scroll-enabled {
    overflow: auto;
  }

  .notification-panel {
    background-color: #FFFFFF;
    overflow: auto;
    min-height: 41px;
    max-height: calc(100vh - 48px);
    padding-top: 10px;
  }
  div.notification-item {
    width: 100%;
    background-color: #FFF;
    user-select: none;
    cursor: pointer;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    min-height: 50px;
    max-width: 100%;
    align-items: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #000000;
    text-align: left;

    padding: 44px;
  }
  div.notification-item:hover {
    background-color: rgba(174, 178, 211,0.15);
  }
  div.notification-item div {
    padding-right: 35px;
  }
  .notification-icon {
    padding-right: 12px;
    font-size: 18px;
  }
  .title{
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 125% !important;
    color: #1E2144 !important;
  }
  .subtitle {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 125% !important;
    width: 280px !important;
    color: #828282 !important;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .time {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #444975;

  }
  .badge {
      background-color: #2DB9D1;
      border-radius: 100%;
      min-width: 12px;
      height: 12px;
  }

  .grey{
    background-color: #BDBDBD;
  }

  .circle{
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }

  .circle p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF; 
    width:40px;
    height: 40px;
    padding-left:13px;
    display: flex;
    align-items: center;
  }

  hr{
    margin: 0px 44px;
  }
</style>
