import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/modules/authentication"
import tabNavigation from "@/modules/tabNavigation"
import dashboard from '@/modules/dashboard'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth']
})

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    tabNavigation,
    dashboard
  },
  plugins:[ vuexLocal.plugin ]
})
