import Vue from 'vue';

export default {
    getClientes(parametros){
        return Vue.axios.get("clientes/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCliente(id){
        return Vue.axios.get("clientes/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateCliente(parametros){
        return Vue.axios.put("clientes", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteCliente(id){
        return Vue.axios.delete("clientes/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addCliente(parametros){
        return Vue.axios.post("clientes", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateLogo(parametros){
        return Vue.axios.post("clientes/update_logo", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteLogo(parametros){
        return Vue.axios.post("empresas/delete_logo", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },

}