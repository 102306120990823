//IMPORTS

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "../router";
import { BASE_URL } from "./variables"

/*
  Se define la url base que se usara para las peticiones con axios.
*/

var host = window.location.hostname;
let baseUrl = BASE_URL;

if (host === "payroll.cliker.com.mx" || host === "www.payroll.cliker.com.mx") {
  baseUrl = "https://apigdth.cliker.com.mx/api/";
} else if (
  host === "devmid19.pbmg.com.mx" ||
  host === "www.devmid19.pbmg.com.mx" ||
  host === "payrolldev.pbmg.com.mx" ||
  host === "www.payrolldev.pbmg.com.mx"
) {
  baseUrl = "https://apidevmid19.pbmg.com.mx/api/";
}

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
var ruta = window.location.href.split("#/");

/*
  axios Interceptor: funcion axxios que nos ayuda a recepcionar 
  todos los errores que recibe de las apis y en base a ello 
  ejecutar nuevos metodos.
*/

var token = localStorage.getItem("tokenPB");

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(e) {
    if (e.response.statusText == "Unauthorized" && ruta[1] != "login") {
      if (token) 
        router.push("/");
    } 
    return Promise.reject(e);
  }
);

/*
  Si existe un token en las sesiones del sistema se añadira a los headers 
  para ser usado como llave de acceso a las api rest.
*/
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

Vue.use(VueAxios, axios);
