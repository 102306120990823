import Swal2 from "sweetalert2";
import Swal from "sweetalert2";

var _buttonColor          = "#004BAF";
var _buttonCancelColor    = "#D41010";
var _confirmText          = "Aceptar";
var _cancelText           = "Cancelar";
var _deleteText           = "Eliminar";

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000
});

function Confirm(title, text, confirmText, cancelText, fnConfirm = null, fnCancel = null) {
    
    if(confirmText == undefined || confirmText == null) confirmText = _confirmText;
    if(cancelText == undefined || cancelText == null) cancelText = _cancelText;

    Swal2.fire({
      title                     : title,
      text                      : text,
      type                      : 'question',
      imageUrl                  : '/static/icon/alert_warning.png',
      width                     : 600,
      allowOutsideClick         : false,
      confirmButtonColor        : _buttonColor,
      confirmButtonText         : confirmText,
      showCancelButton          : true,
      cancelButtonText          : cancelText,
    }).then((handlerEvent) => {
      if(handlerEvent.value) {
        if( fnConfirm != undefined && fnConfirm != null ) fnConfirm();
      } else {
        if( fnCancel != undefined && fnCancel != null ) fnCancel();
      }
    });
    $(".swal2-modal").css("border-radius", "20px");
  
  $(".swal2-actions").css({
    'display': 'white',
    'text-align': 'center',
  });
  /* euicab clase para el boton de ok */
  $(".swal2-confirm").css({
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 'bold',
    'font-size': '14px',
    'line-height': '115.9%',
    'color': '#FFFFFF',
    'background-color': '#1E2245',
    'border-radius': '48px',
    'width': '280px',
    'height': '40px',
  });
  $(".swal2-styled").css({
    'box-shadow': 'none',
  });
  $("#swal2-title").css({
    'color': '#828282',
    'font-family': "\'Montserrat\'",
    'font-size': '24px',
    'font-style': 'normal',
    'font-weight': 'bold',
    'line-height': '115.9%',
  });

  $(".swal2-close").css({
    'top'           : '15px',
    'right'         : '15px',
    'width'         : '28px',
    'height'        : '28px',
    /* euicab Se elimina el border radius 
    'border'        : '2px solid #828282', 
    'border-radius' : '50%',*/
    'color'         : '#828282',
    'font-size'     : '1.8em',
  });

  $(".swal2-content").css({
    'padding-top'   : '30px',
    'font-family'   : "\'Montserrat\'",
    'font-style'    : 'normal',
    'font-weight': 'bold',
    'font-size'     : '18px',
    'line-height'   : '115.9%',
    'text-align'    : 'center',
    'color'         : '#828282'
  });

  $(".swal2-cancel").css({
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 'bold',
    'font-size': '14px',
    'line-height': '115.9%',
    'color': '#000000',
    'background-color': '#828282',
    'border-radius': '48px',
    'width': '280px',
    'height': '40px',
  });
}


function Timer(title, text, timer = 2000, fnFinish = null) {

    Swal2.fire({
      title                     : title,
      text                      : text,
      type                      : 'info',
      width                     : 600,
      allowOutsideClick         : false,
      timer                     : timer
    }).then((handlerEvent) => {
      if( fnFinish != undefined && fnFinish != null ) fnFinish();
    });
}

function Success(title, text,  fnConfirm = null,confirmText = "Aceptar",) {

    if(confirmText == undefined || confirmText == null) confirmText = _confirmText;

    Swal2.fire({
      title                     : title,
      text                      : text,
      width                     : 552,
      height                    : 300,
      /*timer: 3000,*/
      allowOutsideClick         : false,
      showCloseButton           : false,
      showCancelButton          : false,
      showConfirmButton         : true,
      focusConfirm              : false,
      imageUrl                  : '/static/icon/alert_success.png',
    }).then((handlerEvent) => {
      if(fnConfirm != undefined && fnConfirm != null) {
        fnConfirm();
      }
    });

  $(".swal2-modal").css("border-radius", "20px");
  
  $(".swal2-actions").css({
    'display': 'white',
    'text-align': 'center',
  });
  /* euicab clase para el boton de ok */
  $(".swal2-confirm").css({
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 'bold',
    'font-size': '14px',
    'line-height': '115.9%',
    'color': '#FFFFFF',
    'background-color': '#1E2245',
    'border-radius': '48px',
    'width': '280px',
    'height': '40px',
  });
  $(".swal2-styled").css({
    'box-shadow': 'none',
  });
  $("#swal2-title").css({
    'color': '#828282',
    'font-family': "\'Montserrat\'",
    'font-size': '24px',
    'font-style': 'normal',
    'font-weight': 'bold',
    'line-height': '115.9%',
  });

  $(".swal2-close").css({
    'top'           : '15px',
    'right'         : '15px',
    'width'         : '28px',
    'height'        : '28px',
    /* euicab Se elimina el border radius 
    'border'        : '2px solid #828282', 
    'border-radius' : '50%',*/
    'color'         : '#828282',
    'font-size'     : '1.8em',
  });

  $(".swal2-content").css({
    'padding-top'   : '30px',
    'font-family'   : "\'Montserrat\'",
    'font-style'    : 'normal',
    'font-weight': 'bold',
    'font-size'     : '18px',
    'line-height'   : '115.9%',
    'text-align'    : 'center',
    'color'         : '#828282'
  });
}

function SuccessConfiguracion(title, text, confirmText = "Aceptar", fnConfirm = null) {

  if(confirmText == undefined || confirmText == null) confirmText = _confirmText;

  Swal2.fire({
    title                     : title,
    html                      : text,
    width                     : 552,
    height                    : 300,
    /*timer: 3000,*/
    allowOutsideClick         : false,
    showCloseButton           : false,
    showCancelButton          : false,
    showConfirmButton         : true,
    focusConfirm              : false,
    imageUrl                  : '/static/icon/alert_success.png',
  }).then((handlerEvent) => {
    if(fnConfirm != undefined && fnConfirm != null) {
      fnConfirm();
    }
  });

$(".swal2-modal").css("border-radius", "20px");

$(".swal2-actions").css({
  'display': 'white',
  'text-align': 'center',
});
/* euicab clase para el boton de ok */
$(".swal2-confirm").css({
  'font-family': "\'Montserrat\'",
  'font-style': 'normal',
  'font-weight': 'bold',
  'font-size': '14px',
  'line-height': '115.9%',
  'color': '#FFFFFF',
  'background-color': '#1E2245',
  'border-radius': '48px',
  'width': '280px',
  'height': '40px',
});
$(".swal2-styled").css({
  'box-shadow': 'none',
});
$("#swal2-title").css({
  'color': '#1E2245;',
  'font-family': "\'Montserrat\'",
  'font-size': '28px',
  'font-style': 'normal',
  'font-weight': 'bold',
  'line-height': '115.9%',
});

$(".swal2-close").css({
  'top'           : '15px',
  'right'         : '15px',
  'width'         : '28px',
  'height'        : '28px',
  /* euicab Se elimina el border radius 
  'border'        : '2px solid #828282', 
  'border-radius' : '50%',*/
  'color'         : '#828282',
  'font-size'     : '1.8em',
});

$(".swal2-content").css({
  'padding-top'   : '30px',
  'font-family'   : "\'Montserrat\'",
  'font-style'    : 'normal',
  'font-weight': '500',
  'font-size'     : '16px',
  'line-height'   : '115.9%',
  'text-align'    : 'center',
  'color'         : '#1E2245;'
});
}

function Error(title, text, fnConfirm = null, textConfirmButton = 'Entendido') {

  Swal2.fire({
    title                     : title,
    text                      : text,
    type                      : 'error',
    width                     : 600,
    allowOutsideClick         : false,
    showConfirmButton         : true,
    confirmButtonColor        : _buttonColor,
    confirmButtonText         : textConfirmButton,
    imageUrl                  : '/static/icon/advertencia.jpg',
  }).then((handlerEvent) => {
    if(handlerEvent.value) {
      if( fnConfirm != undefined && fnConfirm != null ) fnConfirm();
    }
  });
  /* euicab homolagacion de estilos de notificaciones */
  $(".swal2-modal").css("border-radius", "20px");
    $(".swal2-actions").css({
      'display': 'white',
      'text-align': 'center',
    });
    $(".swal2-image").css({
      'width': '100px',
      'text-align': 'center',
    });
    /* euicab clase para el boton de aceptar*/
    $(".swal2-confirm").css({
      'font-family': "\'Montserrat\'",
      'font-style': 'normal',
      'font-weight': 'bold',
      'font-size': '14px',
      'line-height': '115.9%',
      'color': '#FFFFFF',
      'background-color': '#FF0000',
      'border-radius': '48px',
      'width': '280px',
      'height': '40px',
    });
    $(".swal2-styled").css({
      'box-shadow': 'none',
    });
    $("#swal2-title").css({
      'color': '#FF0000',
      'font-family': "\'Montserrat\'",
      'font-size': '24px',
      'font-style': 'normal',
      'font-weight': 'bold',
      'line-height': '115.9%',
    });

    $(".swal2-close").css({
      'top'           : '15px',
      'right'         : '15px',
      'width'         : '28px',
      'height'        : '28px',
      /* euicab Se elimina el border radius 
      'border'        : '2px solid #828282', 
      'border-radius' : '50%',*/
      'color'         : '#828282',
      'font-size'     : '1.8em',
    });

    $(".swal2-content").css({
      'padding-top'   : '30px',
      'font-family'   : "\'Montserrat\'",
      'font-style'    : 'normal',
      'font-weight': 'bold',
      'font-size'     : '18px',
      'line-height'   : '115.9%',
      'text-align'    : 'center',
      'color'         : '#828282'
    });

}




function Alert(title, text, confirmText = "Aceptar", fnAceptar = null,fnCancelar = null, hasButtons = true, critic = false,){

    if(confirmText == undefined || confirmText == null) confirmText = _deleteText;

    Swal2.fire({
      title                     : title,
      html                      : text,
      width                     : 552,
      height                    : 300,
      allowOutsideClick         : false,
      confirmButtonColor        : _buttonCancelColor,
      confirmButtonText         : confirmText,
      showCloseButton           : false,
      focusConfirm              : false,
      showConfirmButton         : hasButtons,
      showCancelButton          : hasButtons,
      cancelButtonText          : _cancelText,

      imageUrl                  : critic?'/static/icon/peligro.svg':'/static/icon/alert_warning.png',
    }).then((handlerEvent) => {
      if(handlerEvent.value && fnAceptar != undefined && fnAceptar != null) {
        fnAceptar();
      }else if(handlerEvent.dismiss === "cancel" && fnCancelar != undefined && fnCancelar != null){
        fnCancelar();
      }
    
    });

  $(".swal2-modal").css("border-radius", "20px");

  $("#swal2-title").css({
    'padding-top': '15px',
    'color': '#828282',
    'font-family': "'Montserrat'",
    'font-size': '24px',
    'font-style': 'normal',
    'font-weight': 'bold',
    'line-height': '120%',
    'max-width' : '60%',
    'margin-right' : 'auto',
    'margin-left' : 'auto',
  });

  $(".swal2-close").css({
    'top': '15px',
    'right': '15px',
    'width': '28px',
    'height': '28px',
    /* euicab se elimina el border redondo
    'border': '2px solid #828282',
    'border-radius': '50%',
    'font-size': '1.3em a 1.8em
    */
    'color': '#828282',
    'font-size': '1.8em',
  });

  $(".swal2-content").css({
    'padding-top': '3px',
    'font-family': "'Montserrat'",
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '120%',
    'text-align': 'center',
    'max-width' : '70%',
    'margin-right' : 'auto',
    'margin-left' : 'auto',
    'color': '#828282',
  });

  $(".swal2-actions").css({
    'display'       : 'grid',
    'text-align'    : 'center',
  });

  $(".swal2-confirm").css({
    'font-family'   : "\'Montserrat\'",
    'font-style'    : 'normal',
    'font-weight'   : 'bold',
    'font-size'     : '14px',
    'line-height'   : '115.9%',
    'color'         : '#FFFFFF',

    'background-color'  : '#D41010',
    'border-radius'     : '48px',
    'width'             : '280px',
    'height'            : '40px',
  });
  $(".swal2-styled").css({
    'box-shadow': 'none',
  });

  $(".swal2-cancel").css({
    'background-color'  : '#FFF',
    'color'             : '#828282',
    'font-family'       : "\'Montserrat\'",
    'font-style'        : 'normal',
    'font-weight'       : 'bold',
    'font-size'         : '14px',
    'line-height'       : '115.9%',
    'border-radius'     : '48px',
  });

  //$(".swal2-cancel").removeClass('hover');

}


function Alert2(title, text, confirmText = "Aceptar", cancelText = "Cancelar", fnAceptar = null,fnCancelar = null, hasButtons = true, critic = false,){
  Swal2.fire({
    title                     : title,
    html                      : text,
    width                     : 552,
    height                    : 300,
    allowOutsideClick         : false,
    confirmButtonColor        : _buttonCancelColor,
    confirmButtonText         : confirmText,
    showCloseButton           : false,
    focusConfirm              : false,
    showConfirmButton         : hasButtons,
    showCancelButton          : hasButtons,
    cancelButtonText          : cancelText,

    imageUrl                  : critic?'/static/icon/peligro.svg':'/static/icon/alert_warning.png',
  }).then((handlerEvent) => {
    if(handlerEvent.value && fnAceptar != undefined && fnAceptar != null) {
      fnAceptar();
    }else if(handlerEvent.dismiss === "cancel" && fnCancelar != undefined && fnCancelar != null){
      fnCancelar();
    }
  
  });

$(".swal2-modal").css("border-radius", "20px");

$("#swal2-title").css({
  'padding-top': '15px',
  'color': '#828282',
  'font-family': "'Montserrat'",
  'font-size': '24px',
  'font-style': 'normal',
  'font-weight': 'bold',
  'line-height': '120%',
  'max-width' : '60%',
  'margin-right' : 'auto',
  'margin-left' : 'auto',
});

$(".swal2-close").css({
  'top': '15px',
  'right': '15px',
  'width': '28px',
  'height': '28px',
  /* euicab se elimina el border redondo
  'border': '2px solid #828282',
  'border-radius': '50%',
  'font-size': '1.3em a 1.8em
  */
  'color': '#828282',
  'font-size': '1.8em',
});

$(".swal2-content").css({
  'padding-top': '3px',
  'font-family': "'Montserrat'",
  'font-style': 'normal',
  'font-weight': '500',
  'font-size': '18px',
  'line-height': '120%',
  'text-align': 'center',
  'max-width' : '70%',
  'margin-right' : 'auto',
  'margin-left' : 'auto',
  'color': '#828282',
});

$(".swal2-actions").css({
  'display'       : 'grid',
  'text-align'    : 'center',
});

$(".swal2-confirm").css({
  'font-family'   : "\'Montserrat\'",
  'font-style'    : 'normal',
  'font-weight'   : 'bold',
  'font-size'     : '14px',
  'line-height'   : '115.9%',
  'color'         : '#FFFFFF',

  'background-color'  : '#D41010',
  'border-radius'     : '48px',
  'width'             : '280px',
  'height'            : '40px',
});
$(".swal2-styled").css({
  'box-shadow': 'none',
});

$(".swal2-cancel").css({
  'background-color'  : '#FFF',
  'color'             : '#828282',
  'font-family'       : "\'Montserrat\'",
  'font-style'        : 'normal',
  'font-weight'       : 'bold',
  'font-size'         : '14px',
  'line-height'       : '115.9%',
  'border-radius'     : '48px',
});

//$(".swal2-cancel").removeClass('hover');

}

function Info(title, text, confirmText = "Aceptar", fnConfirm = null) {

    if(confirmText == undefined || confirmText == null) confirmText = _confirmText;

    Swal2.fire({
      title                     : title,
      text                      : text,
      type                      : 'info',
      width                     : 600,
      allowOutsideClick         : false,
      confirmButtonColor        : _buttonColor,
      confirmButtonText         : confirmText,
      showCancelButton          : false,
    }).then((handlerEvent) => {
      if(fnConfirm != undefined && fnConfirm != null) {
        fnConfirm();
      }
    });
    $(".swal2-modal").css("border-radius", "20px");
    
    $(".swal2-actions").css({
      'display': 'white',
      'text-align': 'center',
    });
    /* euicab clase para el boton de aceptar*/
    $(".swal2-confirm").css({
      'font-family': "\'Montserrat\'",
      'font-style': 'normal',
      'font-weight': 'bold',
      'font-size': '14px',
      'line-height': '115.9%',
      'color': '#FFFFFF',
      'background-color': '#1E2245',
      'border-radius': '48px',
      'width': '280px',
      'height': '40px',
    });
    $(".swal2-styled").css({
      'box-shadow': 'none',
    });
    $("#swal2-title").css({
      'color': '#828282',
      'font-family': "\'Montserrat\'",
      'font-size': '24px',
      'font-style': 'normal',
      'font-weight': 'bold',
      'line-height': '115.9%',
    });

    $(".swal2-close").css({
      'top'           : '15px',
      'right'         : '15px',
      'width'         : '28px',
      'height'        : '28px',
      /* euicab Se elimina el border radius 
      'border'        : '2px solid #828282', 
      'border-radius' : '50%',*/
      'color'         : '#828282',
      'font-size'     : '1.8em',
    });

    $(".swal2-content").css({
      'padding-top'   : '30px',
      'font-family'   : "\'Montserrat\'",
      'font-style'    : 'normal',
      'font-weight': 'bold',
      'font-size'     : '18px',
      'line-height'   : '115.9%',
      'text-align'    : 'center',
      'color'         : '#828282'
    });

}

function Warning(title, text, confirmText = "Aceptar", fnConfirm = null) {

  if(confirmText == undefined || confirmText == null) confirmText = _confirmText;

  Swal2.fire({
    title                     : title,
    text                      : text,
    type                      : 'warning',
    width                     : 600,
    allowOutsideClick         : false,
    confirmButtonColor        : _buttonColor,
    confirmButtonText         : confirmText,

    showCancelButton          : false,
    imageUrl                  : '/static/icon/alert_warning.png',
  }).then((handlerEvent) => {
    if(fnConfirm != undefined && fnConfirm != null) {
      fnConfirm();
    }
  });

  $(".swal2-modal").css("border-radius", "20px");

  $("#swal2-title").css({
    'color': '#828282',
    'font-family': "\'Montserrat\'",
    'font-size': '24px',
    'font-style': 'normal',
    'font-weight': 'bold',
    'line-height': '115.9%',
  });

  $(".swal2-content").css({
    'padding-top': '30px',
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 500,
    'font-size': '18px',
    'line-height': '115.9%',
    'text-align': 'center',
    'color': '#828282'
  });

  $(".swal2-actions").css({
    'display'       : 'grid',
    'text-align'    : 'center',
  });

  $(".swal2-confirm").css({
    'font-family'   : "\'Montserrat\'",
    'font-style'    : 'normal',
    'font-weight'   : 'bold',
    'font-size'     : '14px',
    'line-height'   : '115.9%',
    'color'         : '#FFFFFF',

    'background-color'  : '#D41010',
    'border-radius'     : '48px',
    'width'             : '280px',
    'height'            : '40px',
  });

  $(".swal2-warning").css({
    'border-color'      : "#828282",
    'color'             : "#828282", 
  });

}

function Restore(title, text, confirmText = "Aceptar", fnAceptar = null) {
  if (confirmText == undefined || confirmText == null) confirmText = _confirmText;

  Swal2.fire({
    title         : title,
    text          : text,
    width         : 552,
    height        : 300,
    allowOutsideClick         : false,
    confirmButtonColor  : _buttonColor,
    confirmButtonText   : confirmText,
    showCloseButton     : false,
    focusConfirm        : false,
    showConfirmButton   : true,
    showCancelButton    : true,
    cancelButtonText    : _cancelText,

    imageUrl: '/static/icon/alert_restore.png',
  }).then((handlerEvent) => {
    if (handlerEvent.value && fnAceptar != undefined && fnAceptar != null) {
      fnAceptar();
    }
  });

  $(".swal2-modal").css("border-radius", "20px");

  $("#swal2-title").css({
    'color': '#828282',
    'font-family': "\'Montserrat\'",
    'font-size': '24px',
    'font-style': 'normal',
    'font-weight': 'bold',
    'line-height': '115.9%',
  });

  $(".swal2-close").css({
    'top': '15px',
    'right': '15px',
    'width': '28px',
    'height': '28px',
    /* euicab se elimina el borde redondo
    'border': '2px solid #828282',
    'border-radius': '50%',*/
    'color': '#828282',
    'font-size': '1.8em',
    'padding-left': '2px',
  });

  $(".swal2-content").css({
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 500,
    'font-size': '18px',
    'line-height': '115.9%',
    'text-align': 'center',
    'color': '#828282',
    'font-weight':'bold',
  });

  $(".swal2-actions").css({
    'display': 'grid',
    'text-align': 'center',
  });

  $(".swal2-confirm").css({
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 'bold',
    'font-size': '14px',
    'line-height': '115.9%',
    'color': '#FFFFFF',
    'background-color': '#004BAF',
    'border-radius': '48px',
    'width': '280px',
    'height': '40px',
  });
  $(".swal2-styled").css({
    'box-shadow': 'none',
  });

  $(".swal2-cancel").css({
    'background-color': '#FFF',
    'color': '#828282',
    'font-family': "\'Montserrat\'",
    'font-style': 'normal',
    'font-weight': 'bold',
    'font-size': '14px',
    'line-height': '115.9%',
     "border-radius": "50px",
  });
}

function TimerWithoutButton(title, text, timer = 3000, fnFinish = null) {

    Swal2.fire({
      title                     : title,
      text                      : text,
      type                      : 'info',
      width                     : 600,
      timer                     : timer,
      showConfirmButton         : false,
      allowOutsideClick         : false

    }).then((handlerEvent) => {
      if( fnFinish != undefined && fnFinish != null ) fnFinish();
    });

  }

function SuccessToast(title) {
    Toast.fire({
      type: "success",
      icon: "success",
      title: title
    });

  $("#swal2-title").css({
    'font-family': "\'Montserrat\'",
    'font-weight': 500,
  });
}

function ErrorToast(title) {

    Toast.fire({
        title: title,
        icon: "error"
    });

  $("#swal2-title").css({
    'font-family': "\'Montserrat\'",
    'font-weight': 500,
  });

}

function WarningToast(title) {

    Toast.fire({
        type: "warning",
        title: title,
        icon : "warning"
    });

    $("#swal2-title").css({
      'font-family': "\'Montserrat\'",
      'font-weight': 500,
    });
}

function QuestionToast(title) {

    Toast.fire({
        type: "question",
        title: title
    });

}

function InfoToast(title) {

    Toast.fire({
        type: "info",
        title: title
    });

}

const Notify = {
    Confirm,
    Timer,
    Success,
    SuccessConfiguracion,
    Error,
    Alert,
    Alert2,
    Info,
    Warning,
    Restore,
    TimerWithoutButton,
    SuccessToast,
    ErrorToast,
    WarningToast,
    QuestionToast,
    InfoToast
}

export default Notify;