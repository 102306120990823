export default [
    {
      path: "*",
      meta: {
        public: true,
      },
      redirect: {
        path: "/404",
      },
    },
    {
    path: "/404",
    meta: {public: true},
    name: "NotFound",
    component: () => import(/* webpackChunkName: "routes" */ `@/views/NotFound.vue`),
    },
    {
    path: '/',
    meta: { public: true, },
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
    },
    {
    path: '/dashboard',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
    },
    //Agregamos routeos para notificaciones
    {
    path: "/notificaciones",
    meta: {
        breadcrumb: true,
        requiresAuth: true,
    },
    name: "notificaciones",
    component: () =>
        import(
        /* webpackChunkName: "routes" */
        `@/views/Notificaciones.vue`
        ),
    },
    {
    path: '/tipoContrato',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoContrato',
    component: () => import(/* webpackChunkName: "tipoContrato" */ '@/views/catalogosSAT/TipoContrato.vue')
    },
    {
    path: '/tipoDeduccion',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoDeduccion',
    component: () => import(/* webpackChunkName: "tipoDeduccion" */ '@/views/catalogosSAT/TipoDeduccion.vue')
    },
    {
    path: '/tipoHora',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoHora',
    component: () => import(/* webpackChunkName: "tipoHora" */ '@/views/catalogosSAT/TipoHora.vue')
    },
    {
    path: '/tipoIncapacidad',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoIncapacidad',
    component: () => import(/* webpackChunkName: "tipoIncapacidad" */ '@/views/catalogosSAT/TipoIncapacidad.vue')
    },
    {
    path: '/tipoJornada',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoJornada',
    component: () => import(/* webpackChunkName: "tipoJornada" */ '@/views/catalogosSAT/TipoJornada.vue')
    },
    {
    path: '/tipoNominaSAT',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoNominaSAT',
    component: () => import(/* webpackChunkName: "tipoNominaSAT" */ '@/views/catalogosSAT/TipoNomina.vue')
    },
    {
    path: '/tipoOtroPago',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoOtroPago',
    component: () => import(/* webpackChunkName: "tipoOtroPago" */ '@/views/catalogosSAT/TipoOtroPago.vue')
    },
    {
    path: '/tipoBaja',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoBaja',
    component: () => import(/* webpackChunkName: "tipoNomina" */ '@/views/catalogos/TipoBaja.vue')
    },
    {
    path: '/tipoPercepcion',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoPercepcion',
    component: () => import(/* webpackChunkName: "tipoPercepcion" */ '@/views/catalogosSAT/TipoPercepcion.vue')
    },
    {
    path: '/tipoRegimen',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'TipoRegimen',
    component: () => import(/* webpackChunkName: "tipoRegimen" */ '@/views/catalogosSAT/TipoRegimen.vue')
    },
    {
    path: '/salarioMinimo',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'SalarioMinimo',
    component: () => import(/* webpackChunkName: "tipoNomina" */ '@/views/catalogos/SalarioMinimo.vue')
    },
    {
    path: '/zonaEconomica',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'ZonaEconomica',
    component: () => import(/* webpackChunkName: "riesgoPuesto" */ '@/views/catalogos/ZonaEconomica.vue')
    },
    {
    path: '/regimenFiscalSAT',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'RegimenFiscalSAT',
    component: () => import(/* webpackChunkName: "regimenFiscal" */ '@/views/catalogosSAT/RegimenFiscal.vue')
    },
    {
    path: '/bancos',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'Bancos',
    component: () => import(/* webpackChunkName: "bancos" */ '@/views/catalogosSAT/Bancos.vue')
    },
    {
    path: '/origenRecursos',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'OrigenRecursos',
    component: () => import(/* webpackChunkName: "origenRecursos" */ '@/views/catalogosSAT/OrigenRecursos.vue')
    },
    {
      path: "/dispositivos",
      meta: { breadcrumb: true, requiresAuth: true },
      name: "dispositivos",
      component: () => import(/* webpackChunkName: "dispositivos" */ `@/views/Dispositivos.vue`),
    },
    {
      path: "/checadorRelacion",
      meta: { breadcrumb: true, requiresAuth: true },
      name: "checadorRelacion",
      component: () => import(/* webpackChunkName: "checadorRelacion" */ `@/views/EmpleadoChecador.vue`),
    },
    {
    path: '/centroCostos',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'CentroCostos',
    component: () => import(/* webpackChunkName: "origenRecursos" */ '@/views/CentroCostos.vue')
    },
    {
    path: '/periodicidadPago',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'PeriodicidadPago',
    component: () => import(/* webpackChunkName: "periodicidadPago" */ '@/views/catalogosSAT/PeriodicidadPago.vue')
    },
    {
    path: '/metodoPago',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'MetodoPago',
    component: () => import(/* webpackChunkName: "metodoPago" */ '@/views/catalogosSAT/MetodosPagos.vue')
    },
    {
    path: '/clases',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'Clases',
    component: () => import(/* webpackChunkName: "clases" */ '@/views/catalogos/Clases.vue')
    },
    {
    path: '/testUsuarios',
    meta: { requiresAuth: true },
    name: 'TestUsuarios',
    component: () => import('@/views/TableUsuarios.vue')
    }, 
    {
    path: '/registroPatronal',
    meta: { breadcrumb: true, requiresAuth: true },
    name: 'RegistroPatronal',
    component: () => import(/* webpackChunkName: "registroPatronal" */ '@/views/RegistroPatronal.vue')
    },
    {
    path: '/configuracion/empresas',
    meta: { requiresAuth: true },
    name: 'empresas',
    component: () => import('@/views/Empresa/Empresas.vue')
    }, 
    {
    path: '/puestos',
    meta: { requiresAuth: true },
    name: 'Puestos',
    component: () => import('@/views/catalogos/Puestos.vue')
    },
    {
    path: '/conceptoNomina',
    meta: { requiresAuth: true },
    name: 'ConceptoNomina',
    component: () => import('@/views/ConceptoNomina.vue')
    },  
    {
    path: '/conceptoNominaTabs',
    meta: { requiresAuth: true },
    name: 'ConceptoNominaTabs',
    component: () => import('@/views/ConceptoNominaTabs.vue')
    }, 
    {
    path: '/tipoNomina',
    meta: { requiresAuth: true },
    name: 'TipoNomina',
    component: () => import('@/views/TipoNomina.vue')
    },  
    {
    path: '/periodos',
    meta: { requiresAuth: true },
    name: 'Periodos',
    component: () => import('@/views/Periodos.vue')
    },
    {
    path: '/calendario',
    meta: { requiresAuth: true },
    name: 'Calendario',
    component: () => import('@/views/Calendario.vue')
    }, 
    {
    path: '/empresasTabs',
    meta: { requiresAuth: true },
    name: 'EmpresasTabs',
    component: () => import('@/views/Empresa/Empresas.vue')
    }, 
    {
    path: '/kardex',
    meta: { requiresAuth: true },
    name: 'Kardex',
    component: () => import('@/views/Kardex/Kardex.vue')
    },
    {
    path: '/empleado/empleadosInactivos',
    meta: { requiresAuth: true },
    name: 'empleado/empleadosInactivos',
    component: () => import('@/views/EmpleadosInactivos.vue')
    },  
    {
    path: '/kardex/detalleEmpleado',
    meta: { requiresAuth: true },
    name: 'detalleEmpleado',
    component: () => import('@/views/Kardex/DetalleEmpleado.vue')
    },
    {
    path: '/prestaciones',
    meta: { requiresAuth: true },
    name: 'prestaciones',
    component: () => import('@/views/Prestaciones.vue')
    },
    {
    path: '/cursos_capacitaciones',
    meta: { requiresAuth: true },
    name: 'cursos_capacitaciones',
    component: () => import('@/views/CursosCapacitaciones.vue')
    },
    {
    path: '/detalleCapacitacion',
    meta: { requiresAuth: true },
    name: 'detalleCapacitacion',
    component: () => import('@/views/DetalleCapacitacion.vue')
    },
    {
    path: '/brigadas',
    meta: { requiresAuth: true },
    name: 'brigadas',
    component: () => import('@/views/Brigadas.vue')
    },
    {
    path: '/usuarios',
    meta: { requiresAuth: true },
    name: 'usuarios',
    component: () => import('@/views/Usuarios.vue')
    },
    {
    path: '/configuracion/sucursales',
    meta: { requiresAuth: true },
    name: '/configuracion/sucursales',
    component: () => import('@/views/Sucursales.vue')
    },
    {
    path: '/grupos',
    meta: { requiresAuth: true },
    name: 'grupos',
    component: () => import('@/views/Grupos.vue')
    },
    {
    path: '/configuracion/departamentos',
    meta: { requiresAuth: true },
    name: 'departamentos',
    component: () => import('@/views/Departamentos.vue')
    // component: () => import('@/views/Departamentos_migrate.vue')
    },
    {
    path: '/configuracion/clientes',
    meta: { requiresAuth: true },
    name: 'configuracion/clientes',
    component: () => import('@/views/Clientes.vue')
    },
    {
      path: "/incidencias",
      meta: { requiresAuth: true},
      name: "incidencias",
      component: () => import('@/views/Incidencias.vue')
    },
    {
    path: '/catalogos/tipo_documentos',
    meta: { requiresAuth: true },
    name: 'catalogos/tipo_documentos',
    component: () => import('@/views/TipoDocumentos.vue')
    },
    {
    path: '/catalogos/tipo_incidencias',
    meta: { requiresAuth: true },
    name: 'catalogos/tipo_incidencias',
    component: () => import('@/views/TipoIncidencias.vue')
    },
    {
    path: '/catalogos/estatus_incidencias',
    meta: { requiresAuth: true },
    name: 'catalogos/estatus_incidencias',
    component: () => import('@/views/EstatusIncidencias.vue')
    },
    {
    path: '/catalogos/estado_civil',
    meta: { requiresAuth: true },
    name: 'catalogos/estado_civil',
    component: () => import('@/views/EstadosCiviles.vue')
    },
    {
    path: '/catalogos/tipo_acciones_disciplinarias',
    meta: { requiresAuth: true },
    name: 'catalogos/tipo_acciones_disciplinarias',
    component: () => import('@/views/TipoAccionesDisciplinarias.vue')
    },
    {
    path: '/empleado/cumpleanios',
    meta: { requiresAuth: true },
    name: 'empleado/cumpleanios',
    component: () => import('@/views/Cumpleanios.vue')
    },
    {
    path: '/empleado/calendario',
    meta: { requiresAuth: true },
    name: 'empleado/calendario',
    component: () => import('@/views/Calendario.vue')
    },
    {
    path: '/empleado/empleados',
    meta: { requiresAuth: true },
    name: 'empleado/empleados',
    component: () => import('@/views/Kardex/Kardex.vue')
    },
    {
    path: '/dias_festivos',
    meta: { requiresAuth: true },
    name: 'dias_festivos',
    component: () => import('@/views/DiasFestivos.vue')
    },
    {
      path: '/anual_fiscal',
      meta: { requiresAuth: true },
      name: 'anual_fiscal',
      component: () => import('@/views/AnualFiscal.vue')
    },
    {
      path: '/anual',
      meta: { requiresAuth: true },
      name: 'anual',
      component: () => import('@/views/CatalogoAnios.vue')
    },
    {
    path: '/catalogos/configuracion_general',
    meta: { requiresAuth: true },
    name: 'catalogos/configuracion_general',
    component: () => import('@/views/ConfiguracionesGenerales.vue')
    },
    {
    path: '/calculoNomina',
    meta: { requiresAuth: true },
    name: 'calculoNomina',
    component: () => import('@/views/CalculoNomina.vue')
    },
    {
      path: '/calculoNominaAcumulado',
      meta: { requiresAuth: true },
      name: 'calculoNominaAcumulado',
      component: () => import('@/views/CalculoNominaAcumulado.vue')
    },
    {
    path: '/horarios',
    meta: { requiresAuth: true },
    name: 'horarios',
    component: () => import('@/views/Horarios.vue')
    },
    {
    path: '/periodoIncidencias',
    meta: { requiresAuth: true },
    name: 'periodoIncidencias',
    component: () => import('@/views/PeriodoIncidencias.vue')
    },
    {
    path: '/capturaNomina',
    meta: { requiresAuth: true },
    name: 'capturaNomina',
    component: () => import('@/views/CapturaNomina.vue')
    },
    {
    path: '/vistas',
    meta: { requiresAuth: true },
    name: 'Vistas',
    component: () => import('@/views/Vistas.vue')
    },
    {
    path: '/incidenciasNominas',
    meta: { requiresAuth: true },
    name: 'IncidenciasNominas',
    component: () => import('@/views/Nominas/Incidencias.vue')
    },
    {
    path: '/diccionario',
    meta: { requiresAuth: true },
    name: 'Diccionario',
    component: () => import('@/views/Diccionario.vue')
    },
    {
    path: '/formulas',
    meta: { requiresAuth: true },
    name: 'Formulas',
    component: () => import('@/views/Formulas.vue')
    },
    {
    path: '/tipoIncidenciaNominas',
    meta: { requiresAuth: true },
    name: 'TipoIncidenciaNominas',
    component: () => import('@/views/TipoIncidenciasNom.vue')
    },
    {
    path: '/prestamos',
    meta: { requiresAuth: true },
    name: 'Prestamos',
    component: () => import('@/views/Nominas/Prestamos/Prestamos.vue')
    },
    {
    path: '/abonos',
    meta: { requiresAuth: true },
    name: 'Abonos',
    component: () => import('@/views/Nominas/Prestamos/Abonos.vue')
    },
    {
    path: '/aguinaldoFiniquito',
    meta: { requiresAuth: true },
    name: 'AguinaldoFiniquito',
    component: () => import('@/views/Nominas/AguinaldoFiniquito.vue')
    },
    {
      path: '/contratos',
      meta: { requiresAuth: true },
      name: 'Contratos',
      component: () => import('@/views/CatalogoContratos.vue')
    },
    {
      path: '/otros-documentos',
      meta: { requiresAuth: true },
      name: 'otrosDocumentos',
      component: () => import('@/views/CatalogoDocumentos.vue')
    },
    {
      path: '/cartas',
      meta: { requiresAuth: true },
      name: 'Cartas',
      component: () => import('@/views/CatalogoCartas.vue')
    },
    {
      path: '/contratos-empleados',
      meta: { requiresAuth: true },
      name: 'ContratosEmpleados',
      component: () => import('@/views/Contratos.vue')
    },
    {
      path: '/redactar-contratos',
      meta: { requiresAuth: true },
      name: 'RedactarContratos',
      component: () => import('@/views/RedactarContratos.vue')
    },
    {
      path: '/redactar-cartas',
      meta: { requiresAuth: true },
      name: 'RedactarCartas',
      component: () => import('@/views/RedactarCartas.vue')
    },
    {
      path: '/redactar-documentos',
      meta: { requiresAuth: true },
      name: 'RedactarDocumentos',
      component: () => import('@/views/RedactarDocumentos.vue')
    },
    {
      path: '/incapacidades',
      meta: { requiresAuth: true },
      name: 'Incapacidades',
      component: () => import('@/views/Nominas/Incapacidades.vue')
    },
    {
      path: '/incapacidadesTipos',
      meta: { requiresAuth: true },
      name: 'IncapacidadesTipos',
      component: () => import('@/views/catalogos/IncapacidadesTipos.vue')
    },
    {
      path: '/incapacidadesRamas',
      meta: { requiresAuth: true },
      name: 'IncapacidadesRamas',
      component: () => import('@/views/catalogos/IncapacidadesRamas.vue')
    },
    {
      path: '/detalleTimbre',
      meta: { requiresAuth: true },
      name: 'DetalleTimbre',
      component: () => import('@/views/DetalleTimbre.vue')
    },
    {
      path: '/asistencia/registros',
      meta: { requiresAuth: true },
      name: 'asistencia/registros',
      component: () => import('@/views/Registros.vue')
    },
    {
      path: '/asistencia/historico_checadas',
      meta: { requiresAuth: true },
      name: 'asistencia/historico_checadas',
      component: () => import('@/views/HistoricoChecadas.vue')
    },
    {
      path: '/idse',
      meta: { requiresAuth: true },
      name: 'IDSE',
      component: () => import('@/views/IDSE.vue')
    },
    {
      path: '/asignacion_checador',
      meta: { requiresAuth: true },
      name: 'asignacion_checador',
      component: () => import('@/views/AsignacionChecadorEmpleado.vue')
    },
    {
      path: '/desacumulados',
      meta: { requiresAuth: true },
      name: 'desacumulados',
      component: () => import('@/views/Desacumulados.vue')
    },
    {
      path: '/detalleAcumulado',
      meta: { requiresAuth: true },
      name: 'detalleAcumulado',
      component: () => import('@/views/DetalleAcumulado.vue')
    },
    {
      path: '/detallePrecalculo',
      meta: { requiresAuth: true },
      name: 'detallePrecalculo',
      component: () => import('@/views/DetallePrecalculo.vue')
    },
    {
      path: '/detalle',
      meta: { requiresAuth: true },
      name: 'detalle',
      component: () => import('@/views/DetalleFiniquitoAguinaldo.vue')
    },
    {
      path: '/acciones_disciplinarias',
      meta: { requiresAuth: true },
      name: 'accionesDisciplinarias',
      component: () => import('@/views/AccionesDisciplinarias.vue')
    },
    {
      path: '/asistencia/checador-webcam',
      meta: { requiresAuth: true },
      name: 'checadorWebcam',
      component: () => import('@/views/ChecadorWebcam.vue')
    },

    {
      path: "/buscador/:search",
      meta: {
        breadcrumb: true,
        requiresAuth: true,
      },
      name: "buscador",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/Buscador.vue`
        ),
      props: true,
    },

    //rutas para el wizard de configuracion inicial
    {
      path: '/bienvenido',
      meta: { public:true},
      name: "bienvenida",
      component: () => import(/* webpackChunkName: "routes" */ `../views/Bienvenida.vue`),
    },
    {
    path: '/paso_uno',
    meta: { public:true},
    name: "Pasouno",
    component: () => import(/* webpackChunkName: "routes" */ `../views/InicioUno.vue`),
    },
    {
    path: '/paso_dos',
    meta: { public:true /*breadcrumb: true, requiresAuth: true, noRoot: true,*/},
    name: "Pasodos",
    component: () => import(/* webpackChunkName: "routes" */ `../views/InicioDos.vue`),
    },
    {
    path: '/paso_tres',
    meta: { public:true/*requiresAuth: true, noRoot: true,*/},
    name: "Pasotres",
    component: () => import(/* webpackChunkName: "routes" */ `../views/InicioTres.vue`),
    },
    {
      path: '/menu',
      meta: { requiresAuth: true },
      name: 'Menu',
      component: () => import('@/views/Menu.vue')
    },
    {
      path: '/tesoreria',
      meta: { requiresAuth: true },
      name: 'Pagos',
      component: () => import('@/views/TesoreriaPagos.vue')
    },
    {
      path: '/tesoreria-pagos',
      meta: { requiresAuth: true },
      name: 'Pagos-tesoreria',
      component: () => import('@/views/TesoreriaPagosMulti.vue')
    },
    {
      path: '/reportes-nomina',
      meta: { requiresAuth: true },
      name: 'reportes-nomina',
      component: () => import('@/views/ReportesNomina.vue')
    },
    /* {
      path: '/reportes/:tipo',
      meta: { requiresAuth: true },
      name: 'reportes',
      component: () => import('@/views/Reportes.vue')
    }, */
    {
      path: '/reportes/general',
      meta: { requiresAuth: true },
      name: 'reportesGeneral',
      component: () => import('@/views/Reportes/Generales.vue')
    },
    {
      path: '/reportes/imss',
      meta: { requiresAuth: true },
      name: 'reportesIMSS',
      component: () => import('@/views/Reportes/DesgloseIMSS.vue')
    },
    {
      path: '/reportes/isr',
      meta: { requiresAuth: true },
      name: 'reportesISR',
      component: () => import('@/views/Reportes/DesgloseISR.vue')
    },
    {
      path: '/fondeo',
      meta: { requiresAuth: true },
      name: 'Fondeo',
      component: () => import('@/views/Fondeo.vue')
    },
    {
      path: '/isn',
      meta: { requiresAuth: true },
      name: 'ISN',
      component: () => import('@/views/ISN.vue')
    },
    {
      path: '/perfilesFacturacion',
      meta: { requiresAuth: true },
      name: 'PerfilesFacturacion',
      component: () => import('@/views/PerfilesFacturacion.vue')
    },
    {
      path: '/paquetesTimbres',
      meta: { requiresAuth: true },
      name: 'paquetesTimbres',
      component: () => import('@/views/Nominas/ConfigTimbres/PaquetesTimbres.vue')
    },
    {
      path: '/configurarTimbres',
      meta: { requiresAuth: true },
      name: 'configurarTimbres',
      component: () => import('@/views/Nominas/ConfigTimbres/ConfigurarTimbres.vue')
    },
    {
      path: '/detalleTimbres',
      meta: { requiresAuth: true },
      name: 'DetalleTimbres',
      component: () => import('@/views/Nominas/ConfigTimbres/DetalleTimbres.vue')
    },
    {
      path: '/polizaContable',
      meta: { requiresAuth: true },
      name: 'PolizaContable',
      component: () => import('@/views/PolizaContable.vue')
    },
    {
      path: '/graficasContratos',
      meta: { requiresAuth: true },
      name: 'GraficasContratos',
      component: () => import('@/views/GraficasContratos.vue')
    },
    {
      path: '/PTU',
      meta: { requiresAuth: true },
      name: 'PTU',
      component: () => import('@/views/PTU.vue')
    },
    {
      path: '/calculadora',
      meta: { requiresAuth: true },
      name: 'Calculadora',
      component: () => import('@/views/Nominas/Calculadora.vue')
    },
    {
      path: '/detallePTU',
      meta: { requiresAuth: true },
      name: 'detallePTU',
      component: () => import('@/views/DetallePTU.vue')
    },
    {
      path: '/incrementoSalario',
      meta: { requiresAuth: true },
      name: 'incrementoSalarial',
      component: () => import('@/views/Nominas/IncrementoSalarial.vue')
    },
    {
      path: '/ajusteAnual',
      meta: { requiresAuth: true },
      name: 'ajusteAnual',
      component: () => import('@/views/Nominas/AjusteAnual.vue')
    },
    {
      path: '/listaCalculoAnual',
      meta: { requiresAuth: true },
      name: 'listaCalculoAnual',
      component: () => import('@/views/Nominas/CalculoAnualLista.vue')
    },
    {
      path: '/detalleCalculoAnual',
      meta: { requiresAuth: true },
      name: 'detalleCalculoAnual',
      component: () => import('@/views/Nominas/CalculoAnualDetalle.vue')
    },
    {
      path: '/beneficiarios',
      meta: { requiresAuth: true },
      name: 'beneficiarios',
      component: () => import('@/views/Kardex/Beneficiarios.vue')
    },
    {
      path: '/contratosCampos',
      meta: { requiresAuth: true },
      name: 'contratosCampos',
      component: () => import('@/views/Contratos/Campos.vue')
    },
    {
      path: '/contratosDatos',
      meta: { requiresAuth: true },
      name: 'contratosDatos',
      component: () => import('@/views/Contratos/Datos.vue')
    },
    {
      path: '/reporteTimbres',
      meta: { requiresAuth: true },
      name: 'reporteTimbres',
      component: () => import('@/views/Reportes/ReporteTimbres.vue')
    },
    {
      path: '/comidas/cocinas',
      meta: { requiresAuth: true },
      name: 'comidas-cocinas',
      component: () => import('@/views/Comidas/Cocinas.vue')
    },
    {
      path: '/comidas/guisos',
      meta: { requiresAuth: true },
      name: 'comidas-guisos',
      component: () => import('@/views/Comidas/Guisos.vue')
    },
    {
      path: '/comidas/complementos',
      meta: { requiresAuth: true },
      name: 'comidas-complementos',
      component: () => import('@/views/Comidas/Complementos.vue')
    },
    {
      path: '/comidas/pedidos',
      meta: { requiresAuth: true },
      name: 'comidas-pedidos',
      component: () => import('@/views/Comidas/Pedidos.vue')
    },
    {
      path: '/largefacelists',
      meta: { requiresAuth: true },
      name: 'largefacelists',
      component: () => import('@/views/Azure/LargeFaceLists.vue')
    },
    {
      path: '/azureRostros',
      meta: { requiresAuth: true },
      name: 'azureRostros',
      component: () => import('@/views/Azure/AzureRostros.vue')
    },
    {
      path: '/timbresIlimitados',
      meta: { requiresAuth: true },
      name: 'timbresIlimitados',
      component: () => import('@/views/Nominas/ConfigTimbres/TimbresIlimitados.vue')
    },
    {
      path: '/detalleTimbreIlimitados',
      meta: { requiresAuth: true },
      name: 'detalleTimbreIlimitados',
      component: () => import('@/views/Nominas/ConfigTimbres/DetalleTimbreIlimitados.vue')
    },
    {
      path: '/notificacionesContratos',
      meta: { requiresAuth: true },
      name: 'notificacionesContratos',
      component: () => import('@/views/Contratos/NotificacionesContratos.vue')
    },
    {
      path: '/catalogos/categoria_empleado',
      meta: { requiresAuth: true },
      name: 'CategoriaEmpleado',
      component: () => import('@/views/catalogos/CategoriaEmpleado.vue')
    },
    {
      path: '/documentos_activos',
      meta: { requiresAuth: true },
      name: 'documentosActivos',
      component: () => import('@/views/DocumentosActivosVista.vue'),
    },
    {
      path: '/descargar_contratos',
      meta: { requiresAuth: true },
      name: 'descargarContratos',
      component: () => import('@/views/DescargarContratos.vue'),
    },
    {
      path: '/documentos',
      meta: { requiresAuth: true },
      name: 'documentos',
      component: () => import('@/views/Documentos.vue'),
    },
    
]