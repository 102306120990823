import Vue from 'vue';

export default{

    getConfiguraciones(parametros){
        return Vue.axios.get("configuraciones/find", {params: parametros}).then((response)=> Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getConfiguracion(id){
        return Vue.axios.get("configuraciones/" + id).then((response)=> Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addConfiguracion(parametros){
        return Vue.axios.post("configuraciones", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateConfiguracion(parametros){
        return Vue.axios.put("configuraciones", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteConfiguracion(id){
        return Vue.axios.delete("configuraciones/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addConfiguracionInicial(parametros){
        return Vue.axios.post("configuraciones/inicial",parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    initialUpdate(parametros) {
        return Vue.axios.post("configuraciones/initialUpdate", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateTimbre(parametros){
        return Vue.axios.post("configuraciones/archivos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}