import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
//import PriceChange from '../components/iconosPersonalizados/PriceChange.vue'
import MaterialIcon from '@/components/iconosPersonalizados/MaterialIcon'
Vue.use(Vuetify);


function missingMaterialIcons(ids) {
  const icons = {}
  for (const id of ids) {
    for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
      const name = `${id}_${suffix}`
      icons[name] = {
        component: MaterialIcon,
        props: {
          name
        }
      }
    }
  }
  return icons
}

export default new Vuetify({
  icons:{
    iconfont: 'md' || 'fa',
    //iconfont: 'fa',
    values: {
        ...missingMaterialIcons([
          'visibility', 
          'visibility_off', 
          'price_change',
          'person_off',
          'notifications',
          'lock',
          'remove_red_eye',
          'get_app',
          'upload',
          'calculate', 
          'shooping_cart',
          'arrow_back'
        ])
    }
    /* values: {
      priceChange: { // nombre de nuestro icono personalizado
        component: PriceChange, // nuestro componente personalizado
      },
    }, */
  },
  /**
   * NOTA:
   * Úselo así
   * <v-icon>$vuetify.icons.priceChange</v-icon>
   * O atajo:
   * <v-icon>$priceChange</v-icon>
   */

  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#1E2245',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#E8404E',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
});
