export async function setDashboard(context,data) {
    context.commit('setDashboard',data);
}

export async function setFatherList(context,data) {
   context.commit('setFatherList',data);
}

export async function setChildList(context,data) {
    context.commit('setChildList',data);;
 }

export async function setCardColaboradores(context, data) {
    context.commit('setCardColaboradores',data);;
}

export async function setCardContratos(context, data) {
    context.commit('setCardContratos',data);;
}

export async function setCardCostoLaboral(context, data) {
    context.commit('setCardCostoLaboral',data);;
}

export async function setCardPercepciones(context, data) {
    context.commit('setCardPercepciones',data);;
}

export async function setCardPagos(context, data) {
    context.commit('setCardPagos',data);;
}

export async function setEnviar(context, data) {
    context.commit('setEnviar',data);
}

export async function setDashboardContratos(context, data){
    context.commit('setDashboardContratos', data);
}

export async function setDashboardCostoSocial(context, data){
    context.commit('setDashboardCostoSocial', data);
}

export async function setDashboardTotalCostoSocial(context, data){
    context.commit('setDashboardTotalCostoSocial', data);
}

//Componente colaboradores
export async function setVuexColaboradoresIngresos(context, data){
    context.commit('setVuexColaboradoresIngresos', data);
}

export async function setVuexColaboradoresRotacion(context,data){
    context.commit('setVuexColaboradoresRotacion', data);
}

export async function setVuexColaboradoresTotal(context, data){
    context.commit('setVuexColaboradoresTotal', data);
}

export async function setVuexColaboradoresTotalIngresos(context, data){
    context.commit('setVuexColaboradoresTotalIngresos', data);
}

export async function setVuexColaboradoresTotalRotacion(context, data){
    context.commit('setVuexColaboradoresTotalRotacion', data);
}

export async function setVuexColaboradoresShowIngresos(context, data){
    context.commit('setVuexColaboradoresShowIngresos', data);
}

export async function setVuexColaboradoresShowRotacion(context, data){
    context.commit('setVuexColaboradoresShowRotacion', data);
}
//Fin componente colaboradores


//Componente Pagos
export async function setVuexPagosAxisX(context, data){
    context.commit('setVuexPagosAxisX', data);
}

export async function setVuexPagosSeries(context, data){
    context.commit('setVuexPagosSeries', data);
}

export async function setVuexPagoList(context, data){
    context.commit('setVuexPagoList', data);
}

export async function setVuexPagosTotal(context, data){
    context.commit('setVuexPagosTotal', data);
}

export async function setVuexPagosShow(context, data){
    context.commit('setVuexPagosShow', data)
}
//Fin Componente Pagos

export async function setVuexShowCardColaboradores(context, data){
    context.commit('setVuexShowCardColaboradores', data);
}

export async function setVuexShowCardContratos(context, data){
    context.commit('setVuexShowCardContratos', data);
}

export async function setVuexShowCardCostoSocial(context, data){
    context.commit('setVuexShowCardCostoSocial', data);
}

export async function setVuexShowCardPagos(context, data){
    context.commit('setVuexShowCardPagos', data);
}