<template>
    <div class="items-center justify-center text-center">
        <v-col cols="12" 
            class="pa-12 blue-grey lighten-5 border-gray-300" 
            style="border-width: 1px;"
            @dragover="dragover" 
            @dragleave="dragleave" 
            @drop="drop"
            :key="keyComponent"
        >
            <input type="file" :multiple="multiple" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                class="" @change="onChange" ref="file" :accept="extensionInput"
                style="overflow: hidden; position: absolute; opacity: 0; height: 1px; width: 1px;"
            />
        
            <label for="assetsFieldHandle" class="textArchivos grey--text" style="display: block; cursor: pointer;">
                <div>
                    Arrastra tu archivo aquí
                    o <span style="	text-decoration: underline;">has clic aquí</span> para cargar tu archivo.
                </div>
            </label>
            <ul class="mt-4 textArchivos" v-if="filelist.length" v-cloak>
                <div class="pa-1" v-for="(file, index) in filelist" :key="index">
                    {{ file.name }}<button class="ml-2 red--text text--lighten-1" type="button" @click="remove(filelist.indexOf(file))" title="Eliminar archivo">Eliminar</button>
                </div>
            </ul>
        </v-col>
    </div>
</template>

<script>
export default {
    props:{
        multiple:{
            type:Boolean,
            default: true
        },
        tamanioArchivo:{
            type:Number,
            default: 10485760 //10 Megabytes
        },
        arrayFile:{
            type:Array,
            default:() => []
        },
        extensionInput:{
            type:String,
            default:'.pdf,.jpg,.jpeg,.png' //'.pdf,.jpg,.jpeg,.png,.txt'
        },
        extensionDrop:{
            type:String,
            default:'.pdf|.jpg|.jpeg|.png' //.pdf|.jpg|.jpeg|.png|.zip|.rar|.xls|.xlsx|.doc|.docx|.pptx'
        }
    },
    data() {
        return {
            filelist:this.arrayFile,
            keyComponent: 1,
        }
    },
    watch:{
        filelist:{
            handler(newValue){
                this.$emit("vModelFile",newValue);
                if(newValue.length == 0){
                    this.keyComponent = this.keyComponent+1
                }
            },
            deep: true
        },
        arrayFile:{
            handler(newValue){
                this.filelist = newValue
            },
            deep: true
        },
    },
    methods: {
        onChange() {
            if([...this.$refs.file.files].length > 1 && this.multiple == false){
                return;
            }
            let tamanio = false
            let extension = false
            let archivos = [...this.$refs.file.files]
            archivos.forEach(archivo => {
                if(archivo.size > this.tamanioArchivo) {
                    tamanio = true
                }
                let tipoExtension = archivo.name.substring(archivo.name.lastIndexOf('.'),archivo.name.length);
                if (!tipoExtension.match(this.extensionDrop)) {
                    extension = true
                }
            });
            
            if(tamanio) return;
            if(extension) return;
            this.filelist = [...this.$refs.file.files];
        },
        remove(i) {
            this.filelist.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();
            // Agregar algo visual para mostrar que el usuario puede soltar sus archivos
            if (!event.currentTarget.classList.contains('teal')) {
                event.currentTarget.classList.remove('blue-grey');
                event.currentTarget.classList.add('teal');
            }
        },
        dragleave(event) {
            // Limpiar
            event.currentTarget.classList.add('blue-grey');
            event.currentTarget.classList.remove('teal');
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Activar el evento onChange manualmente
            // Limpiar
            event.currentTarget.classList.add('blue-grey');
            event.currentTarget.classList.remove('teal');
        }
    },

}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .border-gray-300{
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    }
    .textArchivos{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #828282;
    }
</style>