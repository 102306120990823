const Menu =  [
  {
    title: 'Inicio',
    group: 'apps',
    icon: 'dashboard',
    name: '/Dashboard',
  },
  {
    title: 'Empleados',
    group: 'apps',
    icon: 'group',
    name: '/empleado/empleados',
  },
  {
    title: 'Usuarios',
    group: 'apps',
    icon: 'group',
    name: '/usuarios',
  },
  {
    title: 'Dispositivos',
    group: 'apps',
    icon: 'alarm',
    name: '/dispositivos',
  },
  
  {
    title: 'DiasFestivos',
    group: 'apps',
    icon: 'group',
    name: '/dias_festivos',
  },
  /* {
    title: 'Conexión Dispositivo',
    group: 'apps',
    icon: 'build',
    name: 'conexion_dispositivo',
  }, */
  {
    title: 'Horarios',
    group: 'apps',
    icon: 'alarm',
    name: '/horarios',
  },

  
// Temporal mie ntras se trermina empleados
  /* {
    title: 'Documentos',
    group: 'apps',
    icon: 'group',
    name: 'documentos',
  }, */
  {
    title: 'Incidencias',
    group: 'apps',
    icon: 'group',
    name: '/incidencias',
  },
  {
    title: 'Empleado Checador',
    group: 'apps',
    icon: 'calendar_today',
    name: '/asignacion_checador',
  },
  {
    title: 'Asistencias',
    group: 'apps',
    icon: 'fingerprint',
    name: '/asistencia/registros',
  },
  {
    title: 'Configuraciones',
    group: 'apps',
    icon: 'brightness_low',
    name: '/configuracion/clientes',
  }
];
// reorder menu
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default Menu;
