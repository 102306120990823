export async function updateTabName(context, value) {
    try{
        var data = {
            "name"  : value,
        };

        context.commit('setTabName',data);
    }
    catch(e){
        console.log(e);
    }
}