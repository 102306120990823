<template>
  <div id="appRoot" style="background-color: #EFEFEF;">
    <template v-if="!$route.meta.public && !checador">
      <v-app id="inspire" class="app" style="background-color: #EFEFEF;">
        <v-content id="v-content">
          <template v-if="rol == 'root' || rol == 'admin-sucursal' || rol == 'admin-depto' || rol == 'empleado'">
            <app-toolbar @refreshLogo="refreshLogo" id="app-toolbar" class="app--toolbar" v-show="isLoggedIn" :notification="notification"></app-toolbar>
            <menu-drawer :isOpen="true" :boolLogo="logoBool" width="290" v-show="isLoggedIn" />
          </template>
          <template v-if="rol == 'admin' || rol == 'admin-empresa'">
            <app-toolbar @refreshLogo="refreshLogo" v-if="!$root.configuracionesIniciales" id="app-toolbar" class="app--toolbar" v-show="isLoggedIn" :notification="notification"></app-toolbar>
            <menu-drawer :isOpen="true" v-if="!$root.configuracionesIniciales" :boolLogo="logoBool" width="290" v-show="isLoggedIn" />
          </template>
          
          
          <div class="page-wrapper" >
            <router-view @setNotifications="setNotifications" @comportamiento="comportamiento" ></router-view>
          </div>   
        </v-content>
        <app-fab></app-fab>
        <modals-container/>
      </v-app>
    </template>

    <template v-else>
      <transition>
        <keep-alive>
          <router-view :key="$route.fullpath" @loggedIn="loggedIn"></router-view>
        </keep-alive>
      </transition>
    </template>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click.native="snackbar.show = false" icon> 
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
//import AppMenu from '@/components/AppMenu';
import MenuDrawer from '@/components/MenuDrawer';
import AppToolbar from '@/components/AppToolbar';
import AppFab from '@/components/AppFab';
import PageHeader from '@/components/PageHeader';
import { mapActions, mapState } from 'vuex';
import AppEvents from  './event';

export default {
  name: 'App',
  components: {
    //AppMenu,
    MenuDrawer,
    AppToolbar,
    AppFab,
    PageHeader
  },

  data: () => ({
    isLoggedIn    : false,
    expanded      : true,
    logoBool      :false,
    primera_vez   : true,
    checador      : false,
    snackbar    : {
      show: false,
      text: '',
      color: '',
    },
    props: {
      newLogo: {
        type: Boolean,
        default: false,
      },
    },
    notification: [],
    datosUsuario: null,
    rol         : null,
  }),
  computed: {
    ...mapState('auth',['error','errorMessage','datos', 'menu','isLogged']),
  },
  updated() {
    if (this.$session.get("usuario") != undefined) {
      var datosLogin = this.$session.get("usuario");
      this.datosUsuario = this.$session.get("usuario");
      this.rol = this.datosUsuario.rol.name;

      if(datosLogin.rol.name == "checador"){
        this.checador = true;
        this.primera_vez = datosLogin.configuracion;
        this.$root.configuracionesIniciales = datosLogin.configuracion;
        this.$nextTick(function () {
          this.checador = true;
        })
        
      }else{
        this.checador = false;
        this.primera_vez = datosLogin.configuracion;
        this.$root.configuracionesIniciales = datosLogin.configuracion;
      }
    }
  },
  created() {
    this.isLoggedIn = this.isLogged;
    if(this.isLoggedIn){
    if(this.$session.get('usuario') != undefined) {
      var datosLogin = this.$session.get('usuario');
      this.primera_vez = datosLogin.configuracion;
      this.$root.configuracionesIniciales = datosLogin.configuracion;

      this.datosUsuario = this.$session.get("usuario");
      this.rol = this.datosUsuario.rol.name;

      if(datosLogin.rol.name == "checador"){
        this.checador = true;
      }else{
        this.checador = false;
      }

      /* if (this.primera_vez) {
        this._updateUserData();
      } */
      if(this.$root.configuracionesIniciales){
        this._updateUserData();
      }
    }


    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp   = this;
    }
  },
  
  methods: {
    ...mapActions('auth',['updateUserData']),
    async _updateUserData() {
      var datosLogin = this.$session.get('usuario');
      var data = {
        usuario_id: datosLogin.id,
        sistema: 'nominas'
      }

      await this.updateUserData(data);

      if(!this.error) {
        this.$session.destroy();

        this.$session.set('usuario',this.datos);
        this.$session.set('menu', this.menu);
      }
    },
    refixChat(){
      let btn = null; 
      let interval = setInterval(function(){ 
        btn = document.getElementById('launcher');
        if(btn != undefined){
          clearInterval(interval);
          btn.classList.add('launcher');
          setTimeout(()=>{
            btn.classList.add('launcher-hover');
          },5000);
        }
      }, 500);
    },
    refreshLogo(value){
      this.logoBool = value;
    },
    comportamiento(value) {
      this.$emit('closeMenu');
      this.primera_vez = value;
      this.$root.configuracionesIniciales = value;
    },
    loggedIn() {
      this.isLoggedIn = true;
    },
    setNotifications(data) {
      if(data.length > 0) {
        this.notification = data;
      }
    }
  },
  mounted () {
    this.refixChat();
  },
};
</script>

<style scoped>
  .setting-fab{
    top:50%!important; 
    right:0;
    border-radius:0  
  } 
  .page-wrapper{
    min-height:calc(100vh - 64px - 50px - 81px );
  } 
  
  /**Chat elemento */
.inbenta-launcher {
    position: fixed!important;
    right: 40px!important;
    bottom: 40px!important;
    display: -ms-flexbox;
    display: flex;
    -ms-justify-content: center;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 50px;
    border: 2px solid #1E2144;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    height: 60px;
    width: 60px;
    transition: all .2s ease-in 0s;
    z-index: 1024;
    cursor: pointer;
    animation: pop;
    animation-duration: .7s;
}
/**Chat fin elemento */
</style>