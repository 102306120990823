import Vue from 'vue';

export default {
    getUsuarios(parametros){
        return Vue.axios.get("usuarios/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getUsuario(id){
        return Vue.axios.get("usuarios/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateUsuario(parametros){
        return Vue.axios.post("usuarios/update", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteUsuario(id){
        return Vue.axios.delete("usuarios/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addUsuario(parametros){
        return Vue.axios.post("usuarios", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updatePassword(parametros){
        return Vue.axios.put("usuarios/password", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getMenu(id){
        return Vue.axios.get("usuarios/menu", {params: {id: id}}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateMenu(parametros) {
        return Vue.axios.post("usuarios/menu", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    usuarioEmpresas(id){
        return Vue.axios.get("usuario-empresas/" + id ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    usuariosPeriodoIncidencia(parametros) {
        return Vue.axios.post("usuarios/incidencias", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateFoto(parametros){
        return Vue.axios.post("usuarios/uploadProfilePicture", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },

    getUsuariosAdministradores(parametros){
        return Vue.axios.post("usuarios/list-usuarios", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}